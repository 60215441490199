@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.rangeInputWrapper {
  & > div {
    display: flex;
    align-items: center;
    column-gap: 16px;

    & > p {
      font-family: 'Public Sans', sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #68768B;
    }
  }

  .errorMessage {
    color: $error;
    margin: 4px 0 0;
    font-size: 13px;
  }
}