@import "common/styles/variables";
@import "common/styles/mixins";

.root {
  @include layout-flip {
    width: 100%;
  }
}

.row {
  display: grid;
  gap: 16px;
  width: 100%;

  @include layout-flip {
    width: 100%;
    padding: 16px;
    background: white;
    border: 1px solid $deep-gray;
    border-radius: 10px;
  }
}

.item {
  position: relative;
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: $new-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @include layout-flip {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 0;
    font-size: 13px;
    line-height: 15px;
  }

  &:last-of-type {
    margin: 0;
  }

  .label {
    flex-grow: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;

    @include layout-flip {
      justify-content: flex-start;
      margin: 5px 0 0 16px;
      text-align: left;
    }
  }

  .checkbox {
    padding: 16px 0 0;

    @include layout-flip {
      padding: 8px 0 0;
    }
  }
}

.error {
  margin: 4px 0 0;
  font-size: 13px;
  color: $error;
}
