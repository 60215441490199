@import "../../../../../../../common/styles/variables";
@import "../../../../../../../common/styles/mixins";

.testBlock {
  h2 {
    margin: 0 0 50px;
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    color: #1d3b34;

    @include layout-flip {
      margin: 0 0 30px;
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.container {
  width: fit-content;
}
