@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  width: 100%;
  height: 2px;
  background: $deep-gray;

  .accent {
    height: 2px;
    background: $action-color;
  }
}