@import "../../../styles/variables";

.placeholder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 233px;
  margin: 25px 0;
  border-radius: 20px;
  overflow: hidden;
  color: $new-gray;
  background: $light-gray;
}