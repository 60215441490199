@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.wrapper {
  .chooseCreditCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 487px;
    width: 100%;
    max-height: 90%;
    padding: 32px;
    background: white;
    border-radius: 15px;

    @include layout-flip {
      max-width: none;
      padding: 16px 24px;
      border-radius: 30px 30px 0 0;

      .mobileLine {
        width: 15%;
        height: 2px;
        background: #a7b3ce;
      }
    }

    .closeButton {
      @include clean-btn;
      align-self: flex-end;

      @include layout-flip {
        display: none;
      }
    }

    h1 {
      margin: 0 0 16px;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      color: $black-text;

      @include layout-flip {
        align-self: flex-start;
        margin: 32px 0 16px;
        font-size: 23px;
        line-height: 34px;
        letter-spacing: 0.25px;
      }
    }

    .cardSelect {
      width: 100%;
      margin: 0 0 24px;
      overflow-y: auto;

      @include layout-flip {
        margin: 0 0 32px;
      }

      .card {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 0 16px;
        padding: 24px;
        border: 1px solid $deep-gray;
        border-radius: 10px;
        cursor: pointer;

        @include layout-flip {
          padding: 24px 16px;
        }

        &.selected {
          border-color: $action-color;
        }

        &:last-of-type {
          margin: 0;
        }

        input {
          margin: 0 16px 0 0;
        }

        .cardInfo {
          display: flex;
          align-items: center;

          @include layout-flip {
            flex-direction: column;
            align-items: flex-start;
          }

          svg {
            width: 65px;
            height: 46px;
            margin: 0 16px 0 0;

            @include layout-flip {
              width: 41px;
              height: 29px;
              margin: 0 0 2px;
            }
          }

          .text {
            h2 {
              margin: 0 0 8px;
              font-family: "Public Sans", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.01em;
              color: $black-text;

              @include layout-flip {
                margin: 0 0 4px;
                font-size: 14px;
                line-height: 16px;
              }
            }

            p {
              margin: 0;
              font-family: "Public Sans", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.01em;
              color: $new-gray;

              @include layout-flip {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }

    .addAnotherCard {
      align-self: flex-start;

      @include layout-flip {
        align-self: center;
        width: 100%;
        margin: 0 0 8px;
      }
    }

    .selectThisCard {
      width: 100%;
      padding: 18px;
    }
  }
}
