@import "../../../../../common/styles/mixins";

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1334px;
  margin: 0 auto;
  padding: 0 50px 130px 50px;

  @media (min-width: 1600px) {
    min-height: 830px;
  }

  @include layout-flip {
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding: 0 24px 58px;
  }

  .info {
    max-width: 510px;
    flex-shrink: 0;
  }

  .title {
    margin: 0 0 16px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 4px;
      font-size: 33px;
    }
  }

  .text {
    margin: 0 0 32px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 24px;
      font-size: 16px;
    }
  }

  .button {
    max-width: 230px;
    width: 100%;

    @include layout-flip {
      max-width: none;
      padding: 18px 47px;
    }
  }

  .image {
    position: absolute;
    top: 20px;
    right: -15%;
    width: 1084px;

    @media (max-width: 1600px) {
      position: static;
      flex-shrink: 1;
      width: 100%;
      max-width: 60%;
      top: auto;
      right: auto;
    }

    @media (max-width: 1100px) {
      max-width: 50%;
    }

    @include layout-flip {
      max-width: none;
    }

    @media (max-width: 480px) {
      margin: 0 0 25px;
      max-height: 200px;
    }
  }
}
