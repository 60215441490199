@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/mixins.scss";

.verification {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &:after {
    content: "";
    width: 700px;
    height: 370px;
    position: absolute;
    left: 42%;
    bottom: 0;
    background: url("../../../assets/images/frames/frame_bottom.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  &:before {
    content: "";
    width: 123px;
    height: 128px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    background: url("../../../assets/images/frames/frame-right.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}

.verification-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.verification-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: all 0.2s ease-out;

  &:before {
    content: "";
    width: 240px;
    height: 209px;
    position: absolute;
    left: 0;
    top: 127px;
    background: url("../../../assets/images/frames/frame.png") center no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    width: 248px;
    height: 263px;
    left: 0;
    bottom: 0;
    background: url("../../../assets/images/frames/frame-left.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}

.verification-title {
  &__caption {
    position: relative;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 58px;
    transition: all 0.2s ease-out;
    text-align: center;

    &:before {
      content: "";
      margin: 0 auto 24px;
      display: block;
      width: 200px;
      height: 133px;
      background: url("../../../assets/images/Paper airplane.png") center
        no-repeat;
    }
  }

  &__posttitle {
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #68768b;
    text-align: center;
    max-width: 580px;
    margin: 32px 32px;

    & > span {
      display: block;
      margin: 5px 0 0;
    }

    &:first-of-type {
      margin: 24px 0 0;
    }
  }

  &__header {
    position: relative;
    margin: 0 0 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 76px;
    line-height: 1;
    transition: all 0.2s ease-out;
    text-align: center;

    &.error-title {
      margin: 40px 0;
      font-size: 46px;

      @include layout-flip {
        margin: 35px 0 32px;
        font-size: 28px;
      }
    }
  }

  &__link {
    margin: 0 0 120px;
    font-family: "Public Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
}

.verification__text {
  margin: 0 0 32px;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #68768b;
  text-align: center;
  max-width: 580px;
}

.verification-submit {
  margin-bottom: 24px;
  max-width: 383px;
}

.verification-help {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #68768b;
}

@media all and (max-width: 1400px) {
  .verification:after {
    left: 30%;
  }
}
@media all and (max-width: 1024px) {
  .verification {
    &:after,
    &:before {
      display: none;
    }
  }

  .verification-container:before {
    opacity: 0.5;
  }

  .verification-container:after {
    display: none;
  }
}
@media all and (max-width: 480px) {
  .verification-container:before {
    top: 24px;
    height: 149px;
    width: 170px;
    background-size: contain;
  }

  .verification-title__caption {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 35px;
  }

  .verification-title__posttitle {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 19px;
  }

  .verification__text {
    font-size: 16px;
    line-height: 19px;
  }

  .verification-help {
    font-size: 15px;
    line-height: 18px;
  }
}
