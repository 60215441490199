@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.testResult {
  h2 {
    margin: 0 0 50px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    color: $black-text;

    @include layout-flip {
      margin: 0 0 40px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 50px;

    @include layout-flip {
      flex-direction: column;
      margin: 0 0 20px;
    }

    .speedometer {
      margin: 0 86px 24px 0;

      @include layout-flip {
        margin: 0;

        svg {
          width: 270px;
          height: 290px;
        }
      }
    }
  }

  .scoreCard {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 44px 40px;
    background: #ffffff;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    @include layout-flip {
      width: 100%;
      padding: 25px 20px;
    }

    &.raw_text {
      flex-direction: column;

      .text {
        margin-top: 24px;
      }
    }

    .score {
      margin: 0 60px 0 0;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 72px;
      line-height: 72px;

      @include layout-flip {
        margin: 0 16px 0 0;
        font-size: 50px;
        line-height: 50px;
      }
    }

    .resultName {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      color: $black-text;

      @include layout-flip {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

  .infoCard {
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 0 50px;
    padding: 30px 40px;
    background: #ffffff;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    @include layout-flip {
      flex-direction: column;
      margin: 0 0 20px;
      padding: 16px 20px;
    }

    .info {
      margin: 0 40px 0 0;

      @include layout-flip {
        order: 2;
      }

      .header {
        margin: 0 0 8px;

        @include layout-flip {
          display: flex;
          align-items: center;
          margin: 0 0 16px;
        }

        svg {
          width: 40px;
          height: 40px;
          margin: 0 0 16px;

          @include layout-flip {
            margin: 0 16px 0 0;
          }
        }

        h3 {
          margin: 0;
          font-family: "Mulish", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 31px;
          display: flex;
          align-items: center;
          color: $black-text;
        }
      }
    }

    .image {
      width: 326px;
      height: 194px;

      @media (max-width: 1100px) {
        display: none;
      }

      @include layout-flip {
        order: 1;
        display: block;
        width: 279px;
        height: 166px;
        margin: 0 0 16px;
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    width: 100%;
    max-width: 634px;

    @include layout-flip {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
      max-width: none;
    }

    button {
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
    }
  }
}

.text {
  margin: 0;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $new-gray;

  @include layout-flip {
    font-size: 16px;
    line-height: 25px;
  }
}
