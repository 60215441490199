@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.createBtn {
  margin-top: 4px;
}

.malpractice {
  @media (min-width: 992px) {
    .inputCarrier {
      margin: 35px 0 0;
    }
  }

  div:first-child {
    @include layout-flip {
      min-height: 54px;
    }
  }
}

.editInsuranceTitle {
  margin: 24px 0;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 43px;

  @include layout-flip {
    margin: 0 0 16px;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}
