@import "common/styles/variables";
@import "common/styles/mixins";

.planCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 54px;
  background: white;
  border: 2px solid $deep-gray;
  border-radius: 20px;
  max-width: 395px;

  .topPart {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include layout-flip {
      flex-direction: row;
      margin: 0 0 30px 0;
    }
  }

  .bestOffer {
    position: absolute;
    top: 0;
    margin: -15px;
    padding: 5px 32px;
    border: 2px solid $purple;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    background: white;
    color: $purple !important;

    @include layout-flip {
      position: relative;
    }
  }

  .planIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid $action-color;

    span {
      font-size: 24px;
      color: $action-color;
    }

    @include layout-flip {
      width: 38px;
      height: 38px;

      span {
        font-size: 14px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @include layout-flip {
    align-items: flex-start;
    margin: 0 0 22px;
    padding: 24px;
    border-radius: 20px;

    &:last-of-type {
      margin: 0;
    }
  }

  &.selected {
    border-color: $action-color;
    border-radius: 10px;
  }

  .name {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 34px 0 38px 0;

    @include layout-flip {
      min-height: 30px;
      margin: 0;
    }

    h1 {
      margin: 0;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: -0.01em;
      color: $black-text;

      svg {
        @include layout-flip {
          width: 24px;
          height: 24px;
          margin: 0 0 0 23px;
        }
      }

      @include layout-flip {
        font-size: 22px;
        margin: 0 0 0 15px;
      }
    }

    > svg {
      position: absolute;
      transform: translateY(100%);

      @include layout-flip {
        width: 107px;
      }
    }
  }

  .priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 28px 0 26px;

    @include layout-flip {
      flex-direction: row;
      margin: 10px 0 0;
      margin: 24px 0 0;
      width: 100%;
      justify-content: flex-start;
    }

    div:last-of-type {
      font-size: 14px;
      margin: 4px 0 0;

      @include layout-flip {
        margin: 0;
      }
    }

    p {
      margin: 0;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: $new-gray;

      @include layout-flip {
        font-size: 15px;
        line-height: 18px;
        margin-left: auto;
      }
    }

    .price {
      margin: 0 4px;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 63px;
      color: $action-color;

      @include layout-flip {
        margin: 0 8px 0 0;
        font-size: 28px;
        line-height: 35px;
      }
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .feature {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 16px;

      &:last-of-type {
        margin: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
        flex-shrink: 0;
      }

      p {
        margin: 0;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: $black-text;

        & span {
          vertical-align: top;
          font-size: 10px;
          line-height: 0.8;
        }

        &.disabled {
          color: #a7b2cd;
        }
      }
    }
  }

  button {
    margin: auto 0 0;
    font-family: "Public Sans", sans-serif;
    border: 1px solid $action-color;
    box-shadow: 0 15px 20px rgba(79, 229, 212, 0.2);

    &.selected {
      color: $new-gray;
      background: none;
      border-color: $deep-gray;
      box-shadow: none;
      cursor: default;
    }
  }

  .radio {
    position: absolute;
    align-self: flex-end;
    padding: 0;

    :global .radiobutton__button {
      margin: 8px 0 0;
    }

    span {
      margin: 0;
    }
  }
}

.cancellationDate {
  position: absolute;
  bottom: 12px;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $new-gray;
}
