.react-select {
  .react-select {
    &__control {
      font-size: 16px;
      cursor: pointer;
      border: 1px solid #d4e1f2;
      border-radius: 4px;
      line-height: 19px;
      color: #6e7891;
      padding: 10px 6px 11px;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border-color: #3ceac1;
      }
    }
  }

  &__indicator-separator {
    display: none;
  }

  .css-1pahdxg-control {
    border-color: #3ceac1;
    box-shadow: none;
  }
}
