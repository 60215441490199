@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.messages {
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 24px;
    column-gap: 20px;
    justify-content: space-between;
    margin: 0 0 24px;
    padding: 0 24px;

    @media (max-width: 1120px) {
      display: none;
    }

    .headerItem {
      display: flex;
      align-items: center;
      width: fit-content;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: $new-gray;

      svg {
        margin: 0 0 0 8px;
      }

      &.clickable {
        cursor: pointer;
      }

      &.status {
        margin: 0 0 0 16px;
      }
    }
  }

  .messageList {
    max-width: 1009px;
    width: 100%;

    .noMessages {
      color: $new-gray;
    }
  }

  .accordion {
    position: relative;
    background: #ffffff;
    border: 1px solid #d4e1f2;
    border-radius: 10px;
    padding: 16px 24px;

    &:not(:last-child) {
      margin: 0 0 24px;

      @media (max-width: 1120px) {
        margin: 0 0 16px;
      }
    }

    @media (max-width: 1120px) {
      padding: 16px;
    }

    .tab {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 24px;
      gap: 20px;

      @media (max-width: 1120px) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }
    }

    .receiver {
      display: flex;
      align-items: center;
      order: -1;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      letter-spacing: -0.01em;
      color: #68768b;

      a {
        color: #68768b;
      }

      @media (max-width: 1120px) {
        flex-direction: column;
        align-items: baseline;
      }

      img {
        flex-shrink: 0;
        width: 44px;
        height: 44px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;

        @media (max-width: 1120px) {
          margin: 0 0 15px;
        }
      }

      p {
        margin: 0 0 0 5px;
        padding: 0;
      }
    }

    .phoneNumber {
      display: flex;
      align-items: center;
      order: -1;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      letter-spacing: -0.01em;
      color: #68768b;

      @media (max-width: 1120px) {
        order: 2;
      }

      p {
        margin: 0 0 0 9px;
        padding: 0;
      }
    }

    .time {
      display: flex;
      align-items: center;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      letter-spacing: -0.01em;
      color: #68768b;

      p {
        margin: 0 0 0 10px;
        padding: 0;
      }
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 130px;
      width: 100%;
      margin: 0 0 0 16px;
      padding: 10px 0;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: $black-text;
      border-radius: 6px;

      @media (max-width: 1120px) {
        position: absolute;
        top: 16px;
        right: 98px;
      }

      &.delivered {
        background: $action-color;
      }

      &.unavailable,
      &.error {
        color: white;
        background: $red;
      }

      &.offline {
        background: #ffda79;
      }

      @media (max-width: 1100px) {
        order: 1;
        position: absolute;
        align-self: flex-end;
        width: 130px;
      }
    }

    .arrowIcon {
      justify-self: flex-end;

      @media (max-width: 1120px) {
        position: absolute;
        top: 30px;
        right: 38px;
      }
    }

    .panel {
      display: grid;
      align-items: center;
      grid-template-columns: 3fr 1fr;
      max-width: 925px;
      padding: 15px 0 0;

      @media (max-width: 1120px) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 22px 0 0;
      }

      .accordionMessage {
        z-index: 2;
        position: relative;
        max-width: 90%;
        width: fit-content;
        margin: 0;
        padding: 12px 17px;
        background: #f2f6fb;
        border-radius: 20px;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #1f342f;

        @media (max-width: 1120px) {
          margin: 0 0 15px;
        }

        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          right: -15px;
          bottom: -15px;
          width: 32px;
          height: 55px;
          background: #f2f6fb;
          transform: matrix(0.44, -0.9, -0.9, -0.44, 0, 0);
          clip-path: polygon(50% 0%, 0 100%, 100% 100%);

          @media (max-width: 1120px) {
            right: -10px;
            bottom: -12px;
          }
        }
      }

      .accordionError {
        margin: 0;
        padding: 0;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #f55959;
      }
    }
  }
}
