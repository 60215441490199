@import "../../../../../../common/styles/variables.scss";
@import "../../../../../../common/styles/mixins";

.circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 8px 0 0;
  border-radius: 11px;
}

.circle.green {
  background: $action-color;
}

.circle.yellow {
  background: #ffda79;
}

.circle.red {
  background: #fe4949;
}

.switch {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 10px;
  background: white;
  border: 1px solid $deep-gray;
  border-radius: 18px;

  @include layout-flip {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 24px;
    width: 100%;
    padding: 0;
    border: none;
  }

  .available,
  .wait,
  .offline {
    @include clean-btn;
    padding: 24px 45px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: $new-gray;
    border-radius: 15px;
    transition: 0.15s;

    @include layout-flip {
      justify-content: flex-start;
      width: 100%;
      padding: 16px;
      border: 1px solid $deep-gray;
      border-radius: 4px;
    }

    &.selected {
      cursor: default;
    }
  }

  .available.selected {
    color: #27c089;
    background: #defaf6;

    @include layout-flip {
      border-color: #3ceac1;
    }
  }

  .wait.selected {
    color: #c09527;
    background: #faf2de;

    @include layout-flip {
      border-color: #eab93c;
    }
  }

  .offline.selected {
    color: #c02727;
    background: #fadede;

    @include layout-flip {
      border-color: #ea3c3c;
    }
  }

  .disabled {
    background: #6c6c6c;
  }
}
