.surveyProgress {
  display: flex;
  flex-direction: column;
  margin: 100px auto 0;
  text-align: left;
  max-width: 470px;
  height: calc(100% - 100px);
}

.title {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  color: #1f342f;
  margin-top: 35px;
  margin-bottom: 15px;
  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 36px;
  }

  @media (max-width: 1200px) {
    font-size: 26px;
  }
}

.subTitle {
  display: flex;
  align-items: center;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #68768b;

  span {
    margin-left: 8px;
  }
}

.surveyProgressMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  text-align: left;
}

.mobileTitle {
  flex: 0 0 220px;
  max-width: 220px;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #1f342f;
  margin-bottom: 2px;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mobileStepText {
  font-size: 13px;
  letter-spacing: 1px;
  color: #26d5b6;
  text-transform: uppercase;
}

.steps {
  margin-top: 5px;
}

.row {
  display: flex;
  align-items: center;
  margin: 10px 0 0;
}

.scale {
  max-width: 121px;
  margin: 0 9px 0 0;
}
