@import "common/styles/mixins";

.treatmentPreferencesEdit {
  max-width: 803px;
}

.treatmentPreferencesEditRow {
  @include layout-flip {
    & > div {
      margin: 0 0 10px;
    }
  }
}

.treatmentPreferencesEditSelect {
  margin: 0 0 32px;

  & > div > div {
    column-gap: 0;
  }

  div[data-div-role="icon-wrapper"] {
    background: #f4f8fc;
    border-radius: 10px;
  }

  input:checked + label {
    div[data-div-role="icon-wrapper"] {
      background-color: transparent;
      border: $color-main 1px solid;
    }
  }
}

.treatmentPreferencesEditButton {
  max-width: 386px;
  width: 100%;

  @include layout-flip {
    margin: auto 0 0;
  }
}
