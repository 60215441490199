@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins";

.overview {
  display: flex;
  flex-direction: column;

  .greeting {
    margin: 0 0 56px;

    @include layout-flip {
      display: none;
    }

    h1 {
      margin: 7px 0 16px 0;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 58px;
      color: $black-text;
    }

    small {
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $new-gray;
    }
  }

  .stats {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 70px;
    width: fit-content;
    margin: 0 0 56px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      width: 100%;
    }

    @include layout-flip {
      order: 2;
      display: flex;
      justify-content: space-between;
      grid-gap: 0;
      margin: 0 0 16px;
    }

    .statItem {
      display: flex;
      align-items: center;
      flex-basis: fit-content;

      @include layout-flip {
        flex-direction: column;
      }

      .statCircle {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        margin: 0 16px 0 0;
        background-color: $light-mint;
        border-radius: 100px;

        @include layout-flip {
          width: 64px;
          height: 64px;
          margin: 0 0 12px 0;
        }

        svg {
          width: 25px;
        }
      }

      div {
        h1 {
          margin: 0;
          font-family: "Mulish", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 50px;
          display: flex;
          align-items: center;
          color: $black-text;

          @include layout-flip {
            display: block;
            text-align: center;
            font-size: 20px;
            line-height: 25px;
          }
        }

        small {
          font-family: "Public Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.01em;
          color: $new-gray;

          @include layout-flip {
            display: block;
            text-align: center;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2px;

    @include layout-flip {
      order: 1;
    }
  }

  .completionStatusCard {
    padding: 35px 24px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: fit-content;
    margin: 0 30px 30px 0;
    background-color: white;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    @include layout-flip {
      display: none;
    }

    h2 {
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: $black-text;
    }

    small {
      display: block;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: $new-gray;
    }

    > div {
      margin: 0 32px 0 0;

      h2 {
        white-space: nowrap;
        margin: 0 0 16px;
      }

      small {
        margin-bottom: 32px;
      }

      button {
        padding: 11px 16px;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        border-radius: 5px;
      }
    }
  }

  > h2 {
    margin: 0 0 21px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: $black-text;

    @include layout-flip {
      order: 2;
      margin: 0 0 8px;
    }
  }

  .planCards {
    display: flex;
    flex-wrap: wrap;
    margin: -16px;

    @include layout-flip {
      order: 3;
      margin: 0;
    }
  }

  .completeButton {
    max-width: 161px;
    padding: 10px 11px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    border-radius: 5px;

    @include layout-flip {
      order: 1;
      margin: 0 0 8px;
      padding: 11px 17px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
    }
  }

  .mobileCompletionCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px 15px;
    background: #ffffff;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    h2 {
      margin: 0 0 24px;
      font-family: "Public Sans";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.15;
      letter-spacing: -0.01em;
      color: $black-text;
    }

    div > a {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 143px;
      width: 100%;
      height: 39px;
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    @media (min-width: 992px) {
      display: none;
    }
  }
}
