@import "../../../../../common/styles/_variables.scss";

.patients-therapist-header {

  &__claimBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__available {
    margin: 0 0 32px;
  }

  &__claim {
    margin-left: auto;
    padding: 8px;
    color: #D89D03;
    background: #FFE3AA;
    border: 1px solid #D89D03;
    border-radius: 5px;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &__title {
    margin: 0 0 16px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    color: $black-text;

    span {
      flex: 0 1 80%;
    }

    & .like-button {
      flex-shrink: 0;
    }
  }

  &__company {
    margin:  0 0 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;

    p {
      margin: 0;
    }

    a {
      color: $black-text;
      text-decoration: underline;
      transition: 0.1s;

      &:hover {
        color: $accent-color;
      }
    }
  }

  &__verified {
    flex-shrink: 0;
    margin: 0 16px 0 18px;
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: $new-gray;

    b {
      font-weight: 400;
      line-height: 1;
    }

    span {
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      margin: 0 0 24px 0;
      font-size: 20px;

      svg {
        margin: -2px 12px 0 0;
      }
    }
  }

  &__options {
    display: flex;
    align-items: center;
  }

  &__options-text {
    margin: 0 42px 0 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: $new-gray;
  }

  &__options-container {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  &__options-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid $action-color;
    border-radius: 50%;

    svg {
      path {
        fill: #3ceac1;
      }
    }

    & + .patients-therapist-header__options-item {
      margin: 0 0 0 16px;
    }

    &:hover {
      &:before {
        content: attr(aria-label);
        position: absolute;
        padding: 8px;
        top: -53px;
        transform: translateX(calc(-50%));
        left: 50%;
        right: 50%;
        display: table;
        font-size: 16px;
        line-height: 19px;
        color: #1f342f;
        white-space: nowrap;
        background: #3ceac1;
        border-radius: 5px;

        @media (max-width: 1024px) {
          top: -32px;
          font-size: 13px;
          line-height: 15px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: -22px;
        transform: translateX(calc(-50%)) rotate(45deg);
        left: 50%;
        right: 50%;
        display: table;
        width: 8px;
        height: 8px;
        background: #3ceac1;
        @media (max-width: 1024px) {
          top: -5px;
        }
      }
    }
  }
}
