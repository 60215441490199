@import "../../../../common/styles/mixins";

.root {

  .header {
    margin: 19px 0;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    color: $black-text;
  }

  .tabList {
    display: flex;
    margin: 0 0 24px;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include layout-flip {
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab {
    margin: 0 50px 0 0;
    padding: 10px 0 14px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    color: $black-text;
    white-space: nowrap;
    cursor: pointer;
    letter-spacing: 0.01em;

    &:focus-visible {
      outline: none;
    }

    @include layout-flip {
      margin: 0 35px 0 0;
      padding: 0 0 11px;
      font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }

    &:last-of-type {
      margin: 0;
    }

    &--selected {
      border-bottom: 3px solid $action-color;
    }
  }

  .tabPanel {
    padding: 0;
  }

  .title {
    max-width: 387px;
    width: 100%;
    flex-shrink: 0;
    margin: 0 -14px 0 28px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: #1f342f;

    @media (max-width: 1360px) {
      max-width: 250px;
    }

    @include layout-flip {
      margin: 0;

      & + .title {
        display: none;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    max-width: 529px;

    &:not(:last-of-type) {
      margin: 0 0 24px;
    }
  }

  .anotherTitle {
    max-width: 387px;
    width: 100%;
    flex-shrink: 0;
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: #1f342f;

    &:not(:last-of-type) {
      margin: 0 16px 0 0;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .inner {
    @include layout-flip {
      flex-direction: column-reverse;
    }
  }

  .addButton {
    @include layout-flip {
      display: none;
    }
  }

  .mobileAddButton {
    display: none;

    @include layout-flip {
      display: flex;
      margin: 0 0 24px;
    }
  }

  .mobileContent {
    @include layout-flip {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .field {
    display: flex;
    align-items: center;
    margin: 0 0 56px;

    @include layout-flip {
      margin: 0 0 30px;
    }

    .label {
      margin: 0 0 0 16px;
      padding: 0;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      letter-spacing: -0.01em;

      @include layout-flip {
        font-size: 15px;
      }
    }
  }
}
