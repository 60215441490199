@import "../../../../common/styles/mixins";

.addressGrid {
  grid-template-columns: 84px 1fr;
  grid-column-gap: 8px;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sessionRow {
  justify-content: flex-end;
}

.groupTitle {
  margin-top: 22px;
}

.wrapper {
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin: 0 0 20px;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
  }

  @media (max-width: 370px) {
    margin: 0 0 15px;
  }
}

.part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 165px;

  &:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 900px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
  }
}

.separator {
  margin: 0 16px;
}

.separator,
.day {
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
}

.empty {
  font-size: 20px;
}

.inputTime {
  position: relative;

  p {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 115%;

    @media (max-width: 1150px) {
      bottom: -32px;
    }

    @media (max-width: 900px) {
      bottom: -20px;
    }

    @media (max-width: 515px) {
      bottom: -32px;
    }
  }
}
