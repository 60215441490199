@import "../../common/styles/_mixins.scss";

.sliderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 90px 0;

    @include layout-flip {
        padding: 10px 24px 0;
    }
}

.slider {
    width: 100%;
    max-width: 1450px;
    margin: 0 auto;

    .swiper {
        max-width: 1234px;
        padding: 0 0 100px;

        @include layout-flip {
            padding: 0 0 50px;
        }
    }

    @include layout-flip {
        width: 100%;
        max-width: 600px;
    }

    @media (max-width: 480px) {
        :global .swiper-pagination {
            text-align: left;
            left: 25%;
            right: auto;
        }
    }
}

.button {
    margin: 30px 0 0;
}

.articlesSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1334px;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px 40px;

    @media (max-width: 1345px) {
        align-items: center;
    }

    @include layout-flip {
        align-items: center;
        padding: 0 24px 40px;
    }

    h3 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 64px;

        @include layout-flip {
            margin: 30px 0 30px 0;
        }
    }

    .sorting {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 32px 0;
        opacity: 0.5;

        @include layout-flip {
            justify-content: flex-start;
            margin: 0 0 16px 0;
        }

        .typeSort {
            display: flex;
            cursor: pointer;

            .sortingButton {
                display: flex;
                align-items: center;
            }

            div:not(:last-of-type) {
                margin: 0 30px 0 0;

                @include layout-flip {
                    margin: 0 10px 0 0;
                }
            }

            svg {
                margin: 0 16px 0 0 ;

                @include layout-flip {
                    margin: 0 5px 0 0;
                }
            }
        }

        .chosen {
            opacity: 1;
        }
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include layout-flip {
            justify-content: center;
            margin: 0;

            a {
                width: 100%;
            }
        }
    }

    .lowOpacity {
        opacity: 0.3;
    }
}