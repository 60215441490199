@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/_mixins.scss";

.success-message {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &:after {
    content: "";
    width: 700px;
    height: 370px;
    position: absolute;
    left: 42%;
    bottom: 0;
    background: url("../../../assets/images/frames/frame_bottom.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  &:before {
    content: "";
    width: 123px;
    height: 128px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    background: url("../../../assets/images/frames/frame-right.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}

.success-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  max-width: 900px;

  @include layout-flip {
    max-width: 350px;
  }
}

.success-message-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: all 0.2s ease-out;

  &:before {
    content: "";
    width: 240px;
    height: 209px;
    position: absolute;
    left: 0;
    top: 127px;
    background: url("../../../assets/images/frames/frame.png") center no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    width: 248px;
    height: 263px;
    left: 0;
    bottom: 0;
    background: url("../../../assets/images/frames/frame-left.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}

.success-message-title {
  &__caption {
    position: relative;
    margin: 0 0 24px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    text-align: center;
    color: $black-text;

    @include layout-flip {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 16px;
    }

    &.success {
      &:before {
        background: url("../../../assets/images/tick-circle-green.png") center
          no-repeat;
      }
    }

    &.error {
      &:before {
        background: url("../../../assets/images/tick-circle-red.svg") center
          no-repeat;
      }
    }

    &:before {
      content: "";
      margin: 0 auto 40px;
      display: block;
      width: 88px;
      height: 88px;
    }
  }

  &__posttitle {
    margin: 0 0 120px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: $new-gray;
  }
}

@media all and (max-width: 1400px) {
  .success-message:after {
    left: 30%;
  }
}

@media all and (max-width: 1024px) {
  .success-message {
    &:after,
    &:before {
      display: none;
    }
  }

  .success-message-container:before {
    opacity: 0.5;
  }

  .success-message-container:after {
    display: none;
  }
}

@media all and (max-width: 480px) {
  .success-message-container:before {
    top: 24px;
    height: 149px;
    width: 170px;
    background-size: contain;
  }

  .success-message-title__posttitle {
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 18px;
  }
}
