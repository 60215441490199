@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.title {
  max-width: 650px;

  @include layout-flip {
    margin: 0 0 -8px;
  }
}

.search {
  display: none;

  @media all and (max-width: 1024px) {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
}

.error_container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.error {
  border: 1px red solid;
  border-radius: 8px;
  background-color: rgba($color: red, $alpha: 0.3);
  padding: 16px;
  color: red;
  margin-bottom: 20px;
}

.showmore {
  width: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 100px;
    @media (max-width: 1024px) {
      margin-left: 0;
    }
  }
}

.no_results {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $new-gray;
  flex-grow: 1;
}
