@import "../../../../../../../common/styles/mixins";

.wrapper {
  @include layout-flip {
    align-items: center;
    padding: 0 22px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 781px;
  width: 100%;
  padding: 32px 32px 60px;
  background: white;
  border-radius: 15px;

  @include layout-flip {
    padding: 24px;
  }

  .closeButton {
    @include clean-btn;
    align-self: flex-end;
    margin: 0 0 9px;

    svg path {
      stroke: #1f342f;
    }

    @include layout-flip {
      margin: 0 0 17px;
    }
  }

  .title {
    margin: 0 0 40px;
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.1;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 32px;
      line-height: 1.2;
    }
  }

  .subtitle {
    margin: 0 0 40px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 32px;
      font-size: 16px;
    }
  }

  .textarea {
    max-width: 599px;
    width: 100%;
    margin: 0 auto 40px;

    @include layout-flip {
      margin: 0 auto 32px;
    }

    textarea {
      height: 170px;
    }
  }

  .rowButtons {
    display: flex;
    justify-content: space-between;
    max-width: 520px;
    width: 100%;

    @include layout-flip {
      flex-direction: column;
    }
  }

  .button {
    max-width: 250px;
    width: 100%;

    @include layout-flip {
      max-width: 100%;
      padding: 18px 47px;

      &:not(:last-child) {
        margin: 0 0 12px;
      }
    }
  }
}
