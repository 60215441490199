@import "common/styles/variables";
@import "common/styles/mixins";

.question {
  @include layout-flip {
    width: 100%;
  }

  &.intro {
    margin: 0 0 35px;

    @include layout-flip {
      margin: 0 0 30px;
    }

    h3 {
      margin: 0 0 16px;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $black-text;

      @include layout-flip {
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &:not(.intro) {
    margin: 0 0 50px;
    padding: 25px;
    background: #ffffff;
    border: 1px solid #d4e1f2;
    border-radius: 10px;

    @include layout-flip {
      margin: 0 0 30px;
      padding: 0;
      background: transparent;
      border: none;
      width: 100%;
    }

    h3 {
      margin: 0 0 35px;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: $black-text;

      @include layout-flip {
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &.disabled {
    background: #efefef4d;
  }
}
