@import "../../../../common/styles/mixins";

.root {
  padding: 70px 0 0;
  background: #f2f6fb;

  @include layout-flip {
    padding: 10px 0 0;
  }

  .title {
    margin: 0 0 59px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.12;
    text-align: center;
    color: #000000;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 33px;
    }
  }

  .slider {
    max-width: 1565px;
    padding: 0 50px;
    margin: 0 auto;

    @include layout-flip {
      margin-left: 20px;
      min-width: 600px;
      padding: 0 24px;
    }

    @media (max-width: 480px) {
      :global .swiper-pagination {
        left: 27%;
        right: auto;
      }
    }
  }

  .sliderButtons {
    background: #f2f6fb;
  }
}
