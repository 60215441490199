@import "../../styles/variables";
@import "../../styles/mixins";

.paymentRadio {
  display: flex;

  button {
    flex-grow: 1;
    position: relative;
    padding: 22px 40px;
    text-align: center;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $black-text;
    background: white;
    border: 1px solid $deep-gray;

    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }

    &.selected {
      color: white;
      background: $action-color;
      border-color: $action-color;

      @include layout-flip {
        color: $black-text;
        background: $action-color;
      }
    }

    @include layout-flip {
      padding: 22px 0;
    }
  }
}
