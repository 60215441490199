@import "../../../common/styles/_mixins.scss";

.root {
  max-width: 1334px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px 40px;

  @include layout-flip {
    padding: 0 24px 40px;
  }

  .titleImage {
    height: 385px;

    @include layout-flip {
      height: 218px;
      margin: 30px 0 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  h2 {
    margin: 55px 0 32px 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 64px;
    word-break: break-word;

    @include layout-flip {
      font-size: 33px;
      line-height: 40px;
    }
  }

  .captions {
    display: flex;

    @include layout-flip {
      flex-direction: column;

      div {
        margin: 0 0 3px 0;
      }
    }
  }

  .author {
    display: flex;
    align-items: center;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #68768B;

    svg {
      width: 26px;
      height: 26px;
      margin: 0 16px 0 0;

      path {
        fill: #68768B;
      }
    }
  }

  .date {
    display: flex;
    align-items: center;
    margin: 0 0 0 32px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #68768B;

    p {
      margin: 0;
    }

    svg {
      width: 26px;
      height: 26px;
      margin: 0 16px 0 0;

      path {
        fill: #68768B;
      }
    }
  }

  .timeToRead {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #3CEAC1;

    @include layout-flip {
      margin: 0;

      p {
        margin: 0;
      }
    }

    svg {
      width: 26px;
      height: 26px;
      margin: 0 16px 0 0;

      path {
        fill: #3CEAC1;
      }
    }
  }

  .navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px 0;

    @include layout-flip {
      margin: 25px 0 30px 0;
    }
  }

  .linkToAll {
    display: flex;
    align-items: center;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3CEAC1;
    cursor: pointer;
  }


  .mainText {

    p, li {
      text-align: start;
      word-spacing: -0.1ex;
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      color: #131313;
      margin: 25px 0;
      word-break: break-word;

      br {
        display: none;
      }
    }

    blockquote {
      text-align: justify;
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      color: #131313;
      opacity: 0.5;
    }

    img {
     margin: 0 10px;
    }
  }

  .categoryLinks {
    display: flex;

    a {
      max-width: unset;
      width: unset;
      margin: 10px 5px 0 0;
      align-items: unset;

      @include layout-flip {
        margin: 0 5px 0 0;
      }
    }
  }

  .categoryLink {

    p {
      display: block;
      margin: 16px 0 0 0;
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.1px;
      color: #68768B;
      text-decoration: underline;
      text-underline-offset: 2px;

      @include layout-flip {
        font-size: 10px;
        margin: 10px 0 0 ;
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 0 -15px;

    @include layout-flip {
      justify-content: center;
      margin: 0;

      a {
        width: 100%;

      }
    }

    .cardInArticle {
      margin: 15px;
    }
  }
}