@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.editPatientInformation {
  max-width: 848px;
  width: 100%;

  .tabList {
    display: flex;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #d4e1f2;
    list-style: none;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include layout-flip {
      align-items: normal;
    }
  }

  @include layout-flip {
    position: relative;
    max-width: none;
  }

  .zip {
    max-width: 387px;

    @include layout-flip {
      max-width: 100%;
    }
  }

  .search {
    display: none;

    @include layout-flip {
      display: block;
      margin: 0 0 16px;
    }
  }

  .therapyTypes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    width: fit-content;

    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
    }

    @include layout-flip {
      width: 100%;
    }
  }

  .therapyTypesImage {
    position: relative;
    height: 164px;
    width: 150px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include layout-flip {
      width: 100%;
    }
  }

  .therapyTypesInput {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & + label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      user-select: none;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #1f342f;
      text-align: center;

      &:hover,
      &:focus {
        &::after {
          border: 1px solid #4adccc;
        }
      }

      &[for="inperson"]:before {
        background-image: url("../../../../../assets/images/icons/sofa.svg");
      }

      &[for="telephone"]:before {
        background-image: url("../../../../../assets/images/icons/mobile.svg");
      }

      &[for="videochat"]:before {
        background-image: url("../../../../../assets/images/icons/video.svg");
      }

      &[for="textchat"]:before {
        background-image: url("../../../../../assets/images/icons/comment-dots.svg");
      }

      &:before {
        content: "";
        display: inline-block;
        width: 72px;
        height: 72px;
        margin: 0 auto 8px;
        background-color: #defaf6;
        border-radius: 50px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:after {
        content: "";
        display: inline-block;
        padding: 32px 48px;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 1px solid #d4e1f2;
        box-sizing: border-box;
        border-radius: 6px;
        flex-shrink: 0;
        flex-grow: 0;
        vertical-align: middle;
        transition: all 0.3s ease-in-out 0s;
      }
    }

    &:checked + label:after {
      border-color: #3ceac1;
      box-shadow: 0 0 8px #3ceac1;
    }
  }

  .showmore {
    width: 100%;

    & > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include md {
        display: block;
      }
    }
  }

  .no_results {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $new-gray;
    flex-grow: 1;
  }

  .therapyAges {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    width: fit-content;

    @include layout-flip {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      width: 100%;
    }
  }

  .therapyAgesIcons {
    position: relative;
    height: 182px;
    width: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include layout-flip {
      width: 100%;
    }
  }

  .therapyAgesInput {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & + label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      user-select: none;
      font-size: 15px;
      line-height: 18px;
      color: #1f342f;
      text-align: center;

      &:hover,
      &:focus {
        &::after {
          border: 1px solid #4adccc;
        }
      }

      &[for="age-toddler"]:before {
        background-image: url("../../../../../assets/images/icons/toddler.svg");
      }

      &[for="age-child"]:before {
        background-image: url("../../../../../assets/images/icons/children.svg");
      }

      &[for="age-preteen"]:before {
        background-image: url("../../../../../assets/images/icons/teen.svg");
      }

      &[for="age-teen"]:before {
        background-image: url("../../../../../assets/images/icons/teen.svg");
      }

      &[for="age-adult"]:before {
        background-image: url("../../../../../assets/images/icons/adult.svg");
      }

      &[for="age-elder"]:before {
        background-image: url("../../../../../assets/images/icons/elder.svg");
      }

      &:before {
        content: "";
        display: inline-block;
        width: 43px;
        height: 42px;
        margin: 0 auto 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      &:after {
        content: "";
        display: inline-block;
        padding: 32px 48px;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 1px solid #d4e1f2;
        box-sizing: border-box;
        border-radius: 6px;
        flex-shrink: 0;
        flex-grow: 0;
        vertical-align: middle;
        transition: all 0.3s ease-in-out 0s;
      }
    }

    &:checked + label:after {
      border-color: #3ceac1;
      box-shadow: 0 0 8px #3ceac1;
    }
  }

  .price,
  .time {
    max-width: 304px;
    margin: 0 0 16px;

    @include layout-flip {
      max-width: 100%;
    }
  }
}

@include layout-flip {
  .form {
    .button {
      z-index: 1;
      position: absolute;
      top: -64px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 120px;
      width: 100%;
      height: 44px;
      margin: 0;
      padding: 0;
    }
  }
}
