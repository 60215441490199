@import "../../../../../common/styles/_variables.scss";

.modality {
  display: flex;
  align-items: center;
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $new-gray;

  @media (max-width: $desktop-s) {
    font-size: 14px;
  }
}

.modalityIcon {
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  background: $light-gray;
  border-radius: 50%;

  @media (max-width: $desktop-s) {
    width: 32px;
    height: 32px;
  }
}

.modalityText {
  &.modalityTextHidden {
    display: none;
  }
}
