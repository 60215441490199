@import "../../../../../common//styles/mixins";

.loggedOutToPatients {
  max-width: 600px;

  .textContainer {
    > span {
      display: block;
    }
  }

  .button {
    @include clean-btn;
    display: inline;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    color: #26d5b6;
    border-bottom: 1px solid;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}
