@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;

  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $new-gray;

  cursor: pointer;

  svg {
    transform: rotate(90deg);
    margin: 0 10px 1px 0;

    path {
      stroke: $new-gray;
      fill: $new-gray;
    }
  }
}
