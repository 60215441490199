@import "common/styles/variables";

.root {
  position: relative;
  height: 182px;
  width: 165px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 480px) {
    width: calc(50% - 8px);
    &:nth-of-type(odd) {
      margin: 0 8px 16px 0;
    }
    &:nth-of-type(even) {
      margin: 0 0 16px 8px;
    }
  }
}
.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.input + label {
  display: flex;
  flex-direction: column;
  user-select: none;
  font-size: 15px;
  line-height: 18px;
  color: #1f342f;
  text-align: center;

  &:after {
    content: "";
    display: inline-block;
    padding: 32px 48px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid #d4e1f2;
    box-sizing: border-box;
    border-radius: 6px;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    vertical-align: middle;
  }

  span {
    position: relative;
    z-index: 1;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 4;
  cursor: pointer;

  svg {
    width: 42px;
    height: 42px;
  }
}

.input:checked + label:after {
  background: $action-color;
  border: 1px solid #6c6c6c;
  border-radius: 10px;
}

.input:checked + label {
  svg {
    path[fill="white"] {
      fill: $action-color;
    }
  }
}
