@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.popup {
  position: relative;
  max-width: 826px;
  width: 100%;
  height: 298px;
  padding: 80px 54px 20px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include layout-flip {
    display: block;
    border-radius: 30px 30px 0 0;
    height: auto;
    width: 100%;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 54px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    display: none;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $black-text;
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $black-text;
}

.popupField {
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
}

.popupInput {
  width: 100%;
  color: $new-gray;
}

.popupButton {
  max-width: 143px;
  width: 100%;
  margin: 0 0 0 24px;
  padding: 0;
}
