@import "../../common/styles/variables";
@import "../../common/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  background-image: url("../../assets/images/backgrounds/about-background.png");
  background-repeat: no-repeat;
  background-position: center top;

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    background-image: url("../../assets/images/backgrounds/about-mobile-background.png");
  }

  @media (min-width: 1536px) {
    background-image: url("../../assets/images/backgrounds/about-background-4k.png");
    background-size: auto;
  }
}

.content {
  max-width: 1334px;
  width: 100%;
  margin: 32px auto 193px;
  padding: 0 50px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 1.1;
  color: $black-text;

  @include layout-flip {
    margin: 47px 0 60px;
    padding: 0 24px
  }
}

.contactTitle {
  margin: 0 0 40px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  color: $black-text;

  @include layout-flip {
    margin: 0 0 16px;
    font-size: 33px;
    line-height: 41px;
    letter-spacing: 0.25px;
  }
}

.contactSubtitle {
  margin: 0 0 40px;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  color: $new-gray;

  @include layout-flip {
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 20px;
  }
}

.form {
  display: flex;
  align-items: stretch;

  @include layout-flip {
    flex-direction: column;
  }
}

.formColumn {
  flex: 0 1 387px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & + .formColumn {
    flex: 0 1 601px;
    margin: 0 0 0 43px;
  }

  @include layout-flip {
    flex: auto;
    margin: 0;

    + .formColumn {
      flex: auto;
      margin: 0;
    }
  }
}

.input {
  @include layout-flip {
    margin: 0 0 11px;
  }
}

.textarea {
  margin: 0 0 16px;

  textarea {
    height: 127px;
  }
}

.captcha {
  margin: 0 0 16px;
}

.formButton {
  max-width: 234px;
  width: 100%;

  @include layout-flip {
    max-width: none;
  }
}
