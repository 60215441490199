@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.popup {
  position: relative;
  max-width: 826px;
  width: 100%;
  height: 392px;
  padding: 80px 54px 20px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include layout-flip {
    display: block;
    width: 100%;
    height: auto;
    padding: 66px 54px 20px;
    border-radius: 30px 30px 0 0;

    &::before {
      content: "";
      position: absolute;
      margin: 16px 0 0 0;
      left: 44%;
      top: 0;
      width: 12%;
      height: 2px;
      background-color: #a7b3ce;
      border-radius: 20px;
    }
  }
}

.popupContainer {
  max-width: 387px;
  margin: 0 auto;

  @media (max-width: $mobile) {
    max-width: 327px;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 54px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    display: none;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $black-text;

  @media (max-width: $mobile) {
    margin: 0 0 46px;
  }
}

.popupInput {
  width: 100%;
  margin: 0 0 32px;
}

.popupButton {
  max-width: 100%;
  width: 100%;
  margin: 0 0 0;

  @media (max-width: $mobile) {
    margin: 0 0 47px;
  }
}
