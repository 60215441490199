@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.myAccountLayout {
  > h1 {
    margin: 0 0 22px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: $black-text;
  }

  .tabs {
    .tabList {
      display: flex;
      margin: 0 0 32px;
      padding: 0;
      border-bottom: 1px solid #d4e1f2;
      list-style: none;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @include layout-flip {
        align-items: normal;
      }
    }

    > div {
      padding: 0;
    }
  }
}
