.container {
  border: 1px red solid;
  border-radius: 8px;
  background-color: rgba($color: red, $alpha: 0.3);
  padding: 16px;
  color: red;
  margin: 10px auto 20px;
  width: fit-content;

  &.text {
    width: auto;
    border: 0;
    margin: 10px 0 20px;
    padding: 0;
    background: none;
  }
}
