@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.personalInformationMobile {
  .field {
    margin: 0 0 32px;

    &:last-of-type {
      margin: 0;
    }

    h2 {
      margin: 0 0 16px;
      font-family: 'Public Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: $black-text;
    }

    p {
      margin: 0;
      font-family: 'Public Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: $new-gray;
    }
  }
}