@import "../../../../../../common/styles/mixins";

.editAvailabilityForm {
  max-width: 803px;
}

.editAvailabilityRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 38px;

  @media (max-width: $desktop-m) {
    justify-content: center;
    margin: 0 0 32px;
  }
}

.editAvailabilityColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 0 0 377px;
  margin: 0 0 40px;
  padding: 36px 48px;
  border: 1px solid #d4e1f2;
  border-radius: 6px;

  @media (max-width: $desktop-m) {
    max-width: 600px;
    flex: 0 0 100%;
  }

  @media (max-width: $mobile) {
    margin: 0 0 16px;
    padding: 23px 24px;
  }
}

.editAvailabilityIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  margin: 0 auto 8px;
  background: #defaf6;
  border-radius: 50px;
}

.editAvailabilityTitle {
  margin: 0 0 8px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.26;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.editAvailabilityText {
  max-width: 245px;
  margin: 0 auto 40px;
  padding: 0;
  font-size: 16px;
  line-height: 1.18;
  color: $new-gray;

  @media (max-width: $mobile) {
    font-size: 15px;
    margin: 0 auto 32px;
  }
}

.editAvailabilityColumnButtons {
  max-width: 190px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  border: 1px solid #d4e1f2;
  border-radius: 6px;

  @media (max-width: $desktop-m) {
    max-width: 221px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 374px) {
    padding: 6px;
  }
}

.editAvailabilityCheckbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0px -20px;
  padding: 0;

  @media (max-width: $mobile) {
    max-width: 150px;
    margin: 0 auto;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    padding: 10px 16px;
    font-family: "Public Sans";
    font-weight: 400;
    line-height: 19px;
    color: $black-text;
    border: 1px solid $deep-gray;
    border-radius: 6px;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      background: #fafafa;
      border: 1px solid #dedede;
    }

    @media (max-width: $mobile) {
      margin: 0;
    }

    &[data-value="false"] {
      background: #3ceac1;

      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #dedede;
      }
    }
  }

  input {
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      & + span[data-value="true"] {
        background: #3ceac1;

        & + span[data-value="false"] {
          background: transparent;
        }
      }

      & + span {
        &:hover,
        &:focus {
          background: #22c7a0;
          border: 1px solid #dedede;
        }
      }
    }
  }
}

.editAvailabilityStatus {
  margin: -20px 0 40px;
}

.editAvailabilityIntroTitle {
  margin: 0 0 8px;
  font-family: "Mulish", sans-serif;
  font-size: 26px;
  line-height: 1.27;
  color: $black-text;

  @media (max-width: $mobile) {
    font-size: 20px;
  }
}

.editAvailabilityIntroSubTitle {
  display: block;
  margin: 0 0 32px;
  font-size: 15px;
  line-height: 1.5;
  color: $new-gray;

  @media (max-width: $mobile) {
    margin: 0 0 24px;
  }
}

.editAvailabilityToggle {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;

  label {
    margin: 0 0 0 16px;
    font-size: 16px;
    line-height: 1.18;
    color: $black-text;

    @media (max-width: $mobile) {
      font-size: 15px;
    }
  }
}

.editAvailabilityButton {
  margin: 40px 0 0;
  width: 1000%;
  max-width: 384px;

  @media (max-width: $mobile) {
    max-width: 100%;
  }
}
