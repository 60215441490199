@import "../../../../../common/styles/_variables.scss";

.root {
  .inner {
    display: flex;
    margin: 0 0 16px;
  }

  .img {
    width: 100px;
    height: 100px;
    background: $gray;
    border-radius: 10px;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
    }

    div {
      display: none;
    }
  }

  .info {
    margin: 0 0 0 14px;
  }

  .claimBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 10px;
  }

  .claim {
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #D89D03;
    background: #FFE3AA;
    border: 1px solid #D89D03;
    border-radius: 5px;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .available {
    min-width: 192px;
    width: max-content;
    height: 24px;
    margin: 0 0 3px;
    padding: 3px 11px 3px 8px;
    font-size: 14px;

    & > svg {
      width: 10px;
      height: 12px;
      margin: -2px 6px 0 0;
    }

    @media (max-width: 420px) {
      margin: 0 0 10px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 2;
    letter-spacing: 0.25px;
    color: $black-text;

    @media (max-width: 420px) {
      max-width: 210px;
      margin: auto 0 5px;

      span {
        padding: 0;
        line-height: 1.1;
      }
    }
  }

  .company {
    display: block;
    margin: 0 0 4px;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: $black-text;
  }

  a.company {
    text-decoration: underline;
    transition: 0.1s;

    &:hover {
      color: $accent-color;
    }
  }

  .like {
    @media (max-width: 420px) {
      width: 35px;
      height: 35px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .nameIcon {
    margin: 0 0 0 4px;

    @media (max-width: 420px) {
      margin: 0 0 0 3px;
    }
  }

  .subtitle {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: $new-gray;

    span {
      margin: 0 0 6px 0;
    }

    span:first-of-type {
      margin: 10px 0 6px 0;
    }
  }

  .like {
    width: 42px;
    height: 42px;
    margin: auto 0 0 auto;
    flex-shrink: 0;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $deep-gray;
  }

  .optionsText {
    max-width: 159px;
    margin: 0 5px 0 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    //color: $new-gray;
  }

  .optionsContainer {
    margin: 10px 0 0 0;
    padding: 0;
    display: flex;
    list-style: none;
  }

  .optionsItem {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid $action-color;
    border-radius: 50%;

    svg {
      height: 15px;

      path {
        fill: #3ceac1;
      }
    }

    & + .optionsItem {
      margin: 0 0 0 8px;
    }

    &:nth-child(3) {
      svg {
        height: 11px;
      }
    }

    &:hover {
      &:before {
        content: attr(aria-label);
        position: absolute;
        padding: 8px;
        top: -53px;
        transform: translateX(calc(-50%));
        left: 50%;
        right: 50%;
        display: table;
        font-size: 16px;
        line-height: 19px;
        color: #1f342f;
        white-space: nowrap;
        background: #3ceac1;
        border-radius: 5px;

        @media (max-width: 1024px) {
          top: -32px;
          font-size: 13px;
          line-height: 15px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: -22px;
        transform: translateX(calc(-50%)) rotate(45deg);
        left: 50%;
        right: 50%;
        display: table;
        width: 8px;
        height: 8px;
        background: #3ceac1;
        @media (max-width: 1024px) {
          top: -5px;
        }
      }
    }
  }

  .contacts {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin: 0 0 16px;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
  }

  .phone {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.23;
    color: $black-text;

    .phoneText {
      display: flex;
      flex-direction: column;
    }

    .phoneIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      margin: 0 7px 0 0;
      border-radius: 50%;
      border: 1px solid $action-color;

      svg {
        height: 22px;
      }
    }

    small {
      margin: 0 0 0 4px;
      font-size: 15px;
      color: #1f342fa6;
      letter-spacing: -1px;
    }

    &.disabled {
      pointer-events: none;

      .phoneIcon {
        filter: grayscale(1);
      }
    }
  }

  .iconButtonsContainer {
    display: flex;
    gap: 13px;
  }

  .contactsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background: transparent;
    border: 1px solid $deep-gray;
    border-radius: 50px;

    svg {
      height: 20px;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    margin: 0 0 16px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    background: #defaf6;
    border-color: #defaf6;

    &.premium {
      background: $action-color;
      border-color: $action-color;
    }

    &.status_available {
      background: $action-color;
      border-color: $action-color;
    }

    &.status_wait {
      background: #ffda79;
      border-color: #ffda79;
    }

    &.status_offline {
      background: $red;
      border-color: $red;
    }

    svg {
      margin: 0 10px 0 0;
      max-height: 22px;
    }

    & + .button {
      margin: 0 0 46px;
      background: $light-mint;
      border: 1px solid $light-mint;
    }
  }

  .list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
  }

  .listItem {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 35px;
    padding: 8px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 1;
    color: $new-gray;
    letter-spacing: -0.01em;
    background: $light-gray;
    border: 1px solid $deep-gray;
    border-radius: 40px;

    & + .listItem {
      margin: 0 0 0 8px;
    }
  }
}
