@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.personalInformation {
  .profileWrapper {
    margin: 0 0 32px;
    padding: 40px;
    background: white;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    @include md {
      padding: 0;
      margin-bottom: 16px;
      background: none;
      border: none;
      border-radius: 0;
    }
  }

  .wrapper {
    margin: 40px 0 0;
    padding: 48px 0 0;
    border-top: 1px solid #d4e1f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include md {
      margin: 16px 0 0;
      padding: 24px 20px;
      background: white;
      border: 1px solid $deep-gray;
      border-radius: 10px;
    }
  }

  .backdrop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 32px;
    padding: 40px;
    background: white;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    @include md {
      margin: 16px 0 0;
      padding: 24px 20px;
      background: white;
      border: 1px solid $deep-gray;
      border-radius: 10px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  .infoField {
    margin: 0 0 40px;

    &:last-of-type {
      margin: 0;
    }
  }

  h3 {
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $black-text;
    @include md {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }

  small {
    display: block;
    margin: 8px 0 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $new-gray;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;

    @include md {
      flex-direction: column;
    }

    .infoField {
      width: 50%;
    }
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px;
  width: 100%;
  max-width: 292px;

  @include md {
    margin: 0;
    padding: 10px;
    max-width: 120px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
    flex-shrink: 0;
  }
}
