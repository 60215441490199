@import "../../../../../common/styles/mixins";

.root {
  z-index: 1;
  position: relative;
  max-width: 1091px;
  margin: 0 auto;
  padding: 120px 50px 0;

  @include layout-flip {
    padding: 42px 24px 0;
  }

  .lead {
    text-align: center;
    margin: 0 0 64px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.18;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 24px;
      font-size: 16px;
    }
  }

  .title {
    text-align: center;
    margin: 0 0 16px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      font-size: 33px;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;

    @include layout-flip {
      display: block;

      &.reverse {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &:not(:last-of-type) {
      margin: 0 0 16px;

      @include layout-flip {
        margin: 0 0 24px;
      }
    }

    &.reverse {
      .rowImg {
        margin: 0 0 0 80px;

        @include layout-flip {
          margin: 0 auto 24px;
        }
      }
    }
  }

  .rowImg {
    height: 322px;
    margin: 0 80px 0 0;

    @include layout-flip {
      display: block;
      max-width: 261px;
      margin: 0 auto 24px;
      object-fit: contain;
    }
  }

  .rowTitle {
    margin: 0 0 16px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 5px;
      text-align: center;
      font-size: 30px;
    }
  }

  .rowText {
    max-width: 461px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    color: #68768b;

    @include layout-flip {
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
    }

    span {
      display: block;

      @include layout-flip {
        display: inline;
      }
    }
  }

  .button {
    max-width: 250px;
    width: 100%;
    margin: 32px 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      max-width: 500px;
      margin: 24px auto 0;
      padding: 18px 47px;
    }

    @media (max-width: 568px) {
      max-width: none;
    }
  }
}
