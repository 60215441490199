@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.cardInfo {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 24px;
  background: white;
  border: 1px solid $deep-gray;
  border-radius: 10px;

  @include layout-flip {
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 0 0;
    border: none;
    border-top: 1px solid $deep-gray;
    border-radius: 0;
  }

  .card {
    display: flex;
    align-items: center;

    .icon {
      width: 65px;
      height: 46px;
      margin: 0 16px 0 0;

      @include layout-flip {
        width: 41px;
        height: 29px;
        margin: 0 19px 0 0;
      }
    }

    h3 {
      margin: 0 0 8px;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: $black-text;
    }
  }

  .changeCard {
    margin: 0 0 0 auto;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $action-color;
    border: none;
    background: none;
    border-radius: 0;
    border-bottom: 1px solid $action-color;

    &:hover,
    &:focus {
      border-bottom: 1px solid transparent;
    }
  }
}
