@import "../../../../../common/styles/mixins";

.root {
  z-index: 2;
  position: relative;
  margin: 0 auto;

  @include layout-flip {
    height: 100%;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .inner {
    max-width: 1340px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 100px;
    padding: 10px 50px 0;

    @include layout-flip {
      flex-direction: row-reverse;
      margin: 0 auto 46px;
      padding: 0 24px 0;
    }

    @media (max-width: 630px) {
      flex-direction: column;
    }
  }

  .content {
    max-width: 600px;
    position: relative;
    z-index: 1;

    @include md {
      max-width: 400px;
    }

    @media (max-width: 630px) {
      max-width: none;
      width: 100%;
    }
  }

  .title {
    margin: 0 0 15px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.28;
    color: #000000;

    @include layout-flip {
      max-width: none;
      width: 330px;
      margin: 0 0 16px;
      font-size: 33px;
      line-height: 1.2;
      letter-spacing: 0.25px;
    }

    @media (max-width: 768px) {
      width: 220px;
    }

    @media (max-width: 630px) {
      width: auto;
      max-width: 330px;
    }

    span {
      display: block;

      @include layout-flip {
        display: inline;

        span {
          display: block;
        }
      }
    }
  }

  .subtitle {
    margin: 0 0 16px;
    font-size: 20px;
    line-height: 1.6;
    color: #68768b;

    @include layout-flip {
      max-width: 327px;
      margin: 0 0 25px;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .find {
    display: flex;
    align-items: center;

    & > div {
      max-width: 328px;
      width: 100%;

      @media (max-width: 1024px) {
        max-width: none;
      }
    }

    :global(.search) {
      margin: 0;
      padding: 20px 16px 21px;

      @include md {
        padding: 17px 16px 17px;
      }

      @media (max-width: 1024px) {
        max-width: none;
      }
    }

    button {
      margin-left: 15px;
    }

    @include md {
      flex-direction: column;

      > div {
        width: 100%;
      }

      button {
        margin: 10px 0 0;
        padding: 18px 47px;
      }
    }
  }

  .bottomBtn {
    min-width: 250px;

    @include md {
      margin-top: auto;
      width: 100%;
    }
  }

  .image {
    max-width: 50%;
    z-index: 2;
    width: 620px;

    @include md {
      max-width: 55%;
    }

    @include layout-flip {
      max-width: none;
      width: 400px;
      order: -1;
      margin: 5px 0 30px;
    }

    @media (max-width: 420px) {
      width: 100%;
    }
  }

  .secondTitle {
    margin: 0 0 16px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.28;
    text-align: center;
    color: #000000;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 33px;
      line-height: 1.2;
      letter-spacing: 0.25px;
    }

    @media (max-width: 630px) {
      text-align: left;
    }
  }

  .secondText {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-size: 25px;
    line-height: 1.28;
    text-align: center;
    color: #68768b;

    @media (max-width: 1060px) {
      margin: 0 auto;
      max-width: 650px;
    }

    @include layout-flip {
      font-size: 16px;
      line-height: 1.5;
    }

    @media (max-width: 630px) {
      margin: 0;
      max-width: 450px;
      text-align: left;
    }

    span {
      @include min-layout-flip {
        display: block;
      }
    }
  }

  .feel {
    margin: 0 0 120px;

    @include layout-flip {
      margin: 0 auto 46px;
      padding: 0 24px 0;
    }
  }

  .featuredIcons {
    padding: 50px 0 40px;
    background-color: #E5F0FF;

    .slider {

      @include layout-flip {
        padding: 0 40px 60px 0;
      }
    }

    @include layout-flip {
      padding: 25px 0 20px;
    }
  }

  .featuredIconTitle {
    margin: 0 0 60px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    color: #000000;

    @include layout-flip {
      margin: 0 0 36px;
      font-size: 33px;
    }
  }

  .logotypes {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 1200px) {
      margin: 0;
      padding: 0 0 30px;
      scrollbar-width: thin;
      scrollbar-color: #3ceac1 #ffffff;

      &::-webkit-scrollbar {
        height: 10px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        @include layout-flip {
          display: none;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #3ceac1;
        border-radius: 20px;
        width: 50px;
      }
    }

    span svg {
      height: 60px;
      flex-grow: 0;
      flex-shrink: 0;

      @media (max-width: 1200px) {
        margin: 0 -10px 0 40px;
      }
    }

    svg {
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      overflow: auto;

      &:not(:first-of-type) {
        margin: 0 0 0 90px;
      }

      @media (max-width: 1440px) {
        height: 50px;
      }

      @media (max-width: 1200px) {
        height: 50px;

        & + svg {
          margin: 0 -10px 0 40px;
        }
      }

      @include layout-flip {
        width: 100%;
        margin: 0;
      }
    }

    @include layout-flip {
      margin: 0 0 50px;
    }

    @media (max-width: 600px) {
      margin: 0 0 0 50px;
      padding: 0;

      img {
        transform: scale(3.5);
      }
    }
  }

  @include layout-flip {
    :global .swiper-pagination .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
    }
  }
}
