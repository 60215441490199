@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  @include layout-flip {
    padding: 45px 16px 0;
    max-width: 100%;
  }

  .header {
    max-width: 672px;
    width: 100%;
    margin: 0 0 78px;

    h1 {
      margin: 0 0 16px;
      text-align: center;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 58px;
      color: $black-text;

      @include layout-flip {
        font-size: 26px;
        line-height: 34px;
      }
    }

    p {
      margin: 0;
      text-align: center;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: $new-gray;

      @include layout-flip {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .paymentFrequency {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 490px;
    width: 100%;
    margin: 0 0 35px;

    @include layout-flip {
      margin: 0 0 72px;
    }

    .discount {
      display: flex;
      align-items: center;
      margin: 0 0 0 16px;
      border-radius: 50px;
      background-color: $purple;

      svg {
        width: 38px;
        height: 38px;

        path {
          stroke: #9773e3;
          stroke-width: 1.8px;
        }
      }

      p {
        margin: 0;
        padding: 5px 12px;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #ffffff;

        @include layout-flip {
          white-space: nowrap;
        }
      }
    }

    .frequencySelect {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: $new-gray;

        &.selected {
          color: $black-text;
        }

        @include layout-flip {
          white-space: nowrap;
        }
      }

      input {
        margin: 0 16px;

        @include layout-flip {
          margin: 0 13px;
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-gap: 24px;
    max-width: 1233px;
    width: 100%;
    min-height: 592px;
    margin: 0 0 65px 0;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include layout-flip {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      min-height: fit-content;
    }
  }
}

.testimonials {
  width: 100%;
  margin: 0 0 45px;

  @include layout-flip {
    max-width: 100%;
    overflow: hidden;
  }
}

.overviewSlider {
  max-width: 1233px;
}

.sliderTitleClassname {
  font-size: 46px;
  font-weight: 400;
}
