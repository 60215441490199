@import "../../../../../../common/styles/mixins";
@import "../../../../../../common/styles/variables";

.root {
  display: flex;
  align-items: flex-start;
  max-width: 1097px;
  margin: 0 0 24px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    border-bottom: 1px solid #d4e1f2;
  }

  .input {
    max-width: 387px;
    width: 100%;
    flex-shrink: 0;
    margin: 0 16px 0 0;

    label {
      display: none;
    }

    @media (max-width: 1360px) {
      max-width: 250px;
    }

    @media (max-width: 1100px) {
      flex: 0 0 calc(50% - 8px);
      max-width: none;
      margin: 0 0 24px;

      & + .input {
        margin: 0 0 0 16px;
      }

      label {
        display: block;
        margin: 0 0 16px;
        font-size: 16px;
      }
    }
  }

  .row {
    display: flex;
    width: 100%;

    @media (max-width: 1100px) {
      margin: 0 0 24px;
    }
  }

  .button {
    max-width: 250px;
    width: 100%;
    margin: 0 16px 0 0;
    padding: 20px 47px 19px;

    @media (max-width: 1360px) {
      max-width: 200px;
    }

    @media (max-width: 1100px) {
      max-width: none;
      flex: 0 0 calc(50% - 8px);
    }
  }

  .closeButton {
    @include clean-btn;
    margin: 17px 0 0;
    width: 25px;
    height: 25px;

    span {
      display: none;
    }

    svg {
      width: 25px;
      height: 25px;

      path {
        stroke: $black-text;
      }
    }

    @media (max-width: 1100px) {
      cursor: pointer;
      width: 100%;
      height: auto;
      flex: 0 0 calc(50% - 8px);
      margin: 0;
      padding: 20px 47px 19px;
      text-align: center;
      font-family: "Public Sans", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #1f342f;
      background: #defaf6;
      border-radius: 10px;
      border: 1px solid #defaf6;

      span {
        display: block;
      }

      svg {
        display: none;
      }
    }
  }
}
