@import "../../../../../common/styles/variables";

.therapist-left-side {
  max-width: 214px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__avatar {
    position: relative;
    margin: 0 0 32px;
  }

  &__img {
    display: block;
    max-height: 214px;
    min-height: 214px;
    width: 100% !important;
    margin-bottom: 32px !important;

    a {
      pointer-events: none;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    margin: 0 0 16px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;

    svg {
      margin: 0 10px 0 0;
    }

    & + .therapist-left-side__button {
      font-weight: 400;
      font-size: 16px;
      color: $new-gray;
      background: transparent;
      border: 1px solid $deep-gray;

      &:hover {
        border: 1px solid #b2c3d9;
      }
    }

    &:nth-child(4) {
      margin: 0 0 24px;
    }
  }

  &__lead {
    margin: 0 0 24px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: $black-text;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    margin: 0 0 16px;
    padding: 11px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $new-gray;
    background: $light-gray;
    border: 1px solid $deep-gray;
    border-radius: 40px;

    &:nth-child(odd) {
      margin: 0 8px 16px 0;
    }
  }
}
