@import "../../styles/variables";

.field {
  &.inputOptional > div {
    &:before {
      content: attr(data-optional);
      position: absolute;
      padding-left: 8px;
      right: 16px;
      bottom: 18px;
      font-weight: 500;
      font-size: 11px;
      line-height: 24px;
      color: #a7b2cd;
      background: #fff;
    }
  }

  .description, .aboveText {
    color: $new-gray;
    margin: 8px 0 0;
    font-size: 13px;

    &.right {
      text-align: end;
    }

    &.left {
      text-align: start;
    }
  }

  .aboveText {
    margin: 0 0 5px 0;
  }

  .errorMessage {
    color: $error;
    margin: 4px 0 0;
    font-size: 13px;
  }

  &.disabled {
    .inputHolder {
      background: #efefef4d;
    }

    .input {
      background: transparent;
      border-color: transparent;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.label {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.1;
  color: #1f342f;
}

.input {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  color: #1f342f;
  padding: 0;
  outline: none;

  &::placeholder {
    color: $new-gray;
  }

  &.error {
    border-color: $error;
  }

  &:disabled {
    color: rgba($black-text, 0.65);
  }
}

.inputHolder {
  font-family: "Public Sans", sans-serif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 59px;
  padding: 19px 16px;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  width: 100%;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus,
  &.active {
    border: 1px solid #4adccc;
  }

  &.error {
    border-color: $error;

    .inputAdornment,
    .inputAdornmentLeft,
    .hashtag {
      border-color: $error;
    }
  }
}

.inputAdornment {
  margin: 0 0 0 2px;
  font-size: 14px;
  line-height: 1.05;
  color: #a7b2cd;
}

.inputAdornmentLeft {
  margin: 0 2px 0 0;
  font-size: 14px;
  line-height: 1.01;
  color: #a7b2cd;

  &.gray {
    color: #a7b2cd;
  }

  &.black {
    color: $black-text;
  }
}

.input {
  font-size: 16px;
  line-height: 1.1;
  color: #1f342f;
  outline: none;
  border: none;

  &::placeholder {
    color: $new-gray;
  }
}

@media all and (max-width: $layout-flip-var) {
  .label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}

.field .input[readonly] {
  color: $new-gray;
}

.hashtag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 700;
  top: 0;
  position: absolute;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #d4e1f2;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  width: 38px;
  margin: -1px 0 -1px -17px;
}

.hashtagInput {
  margin-left: 28px;
}
