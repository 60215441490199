@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.testPrepare {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  @include layout-flip {
    margin-bottom: 30px;
  }
}

.title {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  font-size: 34px;
  line-height: 43px;
  font-weight: normal;
  color: #1D3B34;

  @include layout-flip {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}

.row {
  display: flex;

  @include layout-flip {
    flex-direction: column;
  }
}

.instructions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid #D4E1F2;
  border-radius: 10px;

  @include layout-flip {
    padding: 0;
    flex-direction: column;
    align-items: normal;
    border: none;
    background: transparent;
  }
}

.wrapper {
  @include layout-flip {
    margin-bottom: 16px;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #D4E1F2;
    border-radius: 10px;
  }
}

.instructionsTitle {
  margin: 0 0 10px;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1F342F;
}

.instructionsText {
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  color: #68768B;
}

.btn {
  margin-left: 40px;
  max-width: 292px;
  width: 100%;

  @include layout-flip {
    margin: 0;
    max-width: 100%;
  }
}