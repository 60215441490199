@import "../../../../../../common/styles/mixins";

.root {
  max-width: 806px;
  width: 100%;

  .body {
    border: 1px solid $deep-gray;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    background: white;
    margin: 0 0 32px;

    @include layout-flip {
      margin: 0 0 24px;
      background: none;
      border: none;
    }
  }

  .typeLabel {
    margin: 32px 0 13px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $black-text;

    @include layout-flip {
      margin: 0 0 16px;
    }
  }

  .type {
    max-width: 90%;

    @include layout-flip {
      max-width: 100%;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .button {
    margin: 54px 0 0;
    max-width: 386px;
    width: 100%;

    @include layout-flip {
      max-width: none;
      margin: 34px 0 0;
    }
  }
}
