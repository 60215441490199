@import "../../styles/mixins";

$small-padding: 18px 0;
$large-padding: 30px 0;

@mixin vertical {
  flex-direction: column;
  column-gap: 0;

  span {
    width: 100%;
    text-align: center;
  }
}

.IconWrapper {
  --icon-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out 0s;

  @include restrict-prop(width, var(--icon-size));
  @include restrict-prop(height, var(--icon-size));
}

.IconCheckboxCardInput {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: flex;
    align-items: center;
    user-select: none;
    background: #fff;
    border: 1px solid #d4e1f2;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    column-gap: 12px;
    padding: 16px;
    transition: all 0.3s ease-in-out 0s;

    svg {
      path {
        transition: all 0.3s ease-in-out 0s;
      }
    }

    @include layout-flip {
      @include vertical;
      row-gap: 16px;
      padding: $small-padding;
    }

    &:hover,
    &:focus {
      border: 1px solid #4adccc;
    }
  }

  &_compact + label {
    @include vertical;
    padding: $large-padding;
    row-gap: 0;

    @include layout-flip {
      padding: $small-padding;
    }

    .IconWrapper {
      --icon-size: 60px;
    }
  }

  &:checked + label {
    border-color: $new-gray;
    background-color: $action-color;

    svg {
      path[fill="white"] {
        fill: $action-color;
      }
    }
  }
}

.label,
.subLabel {
  display: block;
  max-width: 120px;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.label {
  color: #1f342f;
}

.subLabel {
  color: #68768b;
  margin-top: 5px;
}
