@import "common/styles/variables.scss";
@import "common/styles/mixins.scss";

.root {
  background: #fbfcff;
  padding: 0 0 46px;

  @media (max-width: 992px) {
    padding: 32px 0 26px;
  }

  header {
    margin: 0 0 48px;
    padding: 0 52px 0 36px;
  }

  .logo :global .logo {
    margin: 0 auto 0 0;
  }

  .wrapper {
    max-width: 1554px;
    padding: 0 36px;

    @media (max-width: 1200px) {
      padding: 0 23px;
    }
  }

  .title {
    margin: 0 0 32px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.2;
    color: #1d3b34;

    @media (max-width: 992px) {
      margin: 0 0 8px;
      text-align: center;
      font-size: 28px;
    }
  }

  .container {
    padding: 40px 34px 0 40px;
    background: #ffffff;
    border: 1px solid $deep-white;
    border-radius: 10px;

    @media (min-width: 992px) {
      &:not(:last-of-type) {
        margin: 0 0 32px;
      }
    }

    @media (max-width: 992px) {
      margin: 0 0 16px;
      padding: 0;
      background: #fbfcff;
      border: none;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 48px;
    padding: 0 0 40px;
    border-bottom: 1px solid $deep-white;

    @media (max-width: 992px) {
      margin: 0;
      padding: 0 0 16px;
      border: none;
    }
  }

  .info {
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
      margin: 0 auto;
    }
  }

  .img {
    flex-shrink: 0;
    width: 152px;
    height: 152px;
    margin: 0 54px 0 0;
    background: $gray;

    & > img {
      border-radius: 15px;
    }

    @media (max-width: 992px) {
      width: 80px;
      height: 80px;
      margin: 0 0 8px;
    }
  }

  .userTitle {
    margin: 0 0 12px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    color: #1d3b34;

    @media (max-width: 992px) {
      margin: 0 0 8px;
      font-size: 23px;
    }
  }

  .userAge {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: #68768b;

    @media (max-width: 992px) {
      font-size: 15px;
    }
  }

  .list {
    display: grid;
    max-width: 930px;
    margin: 0 -20px;
    grid-template-columns: 0.8fr 0.9fr 1.4fr;
    padding: 0;
    list-style: none;

    @media (max-width: 992px) {
      display: block;
      max-width: none;
      margin: 0;
      padding: 24px 24px 19px;
      background: #ffffff;
      border: 1px solid $deep-white;
      border-radius: 10px;
    }
  }

  .listItem {
    flex: 0 0 auto;
    margin: 0 20px 40px;

    @media (max-width: 992px) {
      margin: 0 0 32px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .listTitle {
    margin: 0 0 16px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.1;
    color: #1d3b34;
  }

  .listText {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1;
    color: #6e7891;

    a {
      color: #26d4b6;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
      font-weight: 500;
    }
  }

  .lead {
    margin: 0 0 30px;
    padding: 0 0 30px;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.2;
    color: #1d3b34;
    border-bottom: 1px solid $deep-white;

    @media (max-width: 992px) {
      margin: 0 0 16px;
      padding: 0;
      text-align: center;
      font-size: 28px;
      font-weight: 400;
      border: none;
    }
  }

  .testList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    margin: 0 -49px;
    padding: 0;
    list-style: none;

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
      padding: 24px 24px 30px;
      background: #ffffff;
      border: 1px solid $deep-white;
      border-radius: 10px;
    }

    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .noTests {
    margin: 0 0 30px;
    font-family: "Public Sans", sans-serif;
    font-size: 17px;
    line-height: 1.1;
    color: #6e7891;

    @media (max-width: 992px) {
      text-align: center;
    }
  }

  .testItem {
    cursor: pointer;
    max-width: 350px;
    margin: 0 49px 25px;

    @media (max-width: 992px) {
      margin: 0 0 32px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .testRow {
    font-family: "Public Sans", sans-serif;
    font-size: 17px;
    line-height: 1.1;
    color: #6e7891;

    &:not(:last-of-type) {
      margin: 0 0 5px;
    }

    b {
      font-weight: 600;
      color: #1d3b34;
    }
  }

  .userInfo {
    padding: 0 10px 0 0;

    @media (max-width: 992px) {
      padding: 0;
      text-align: center;
    }
  }
}
