@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins.scss";

.sessionControls {
  width: 100%;
  max-width: 634px;
  margin: 0 26px 0 0;

  @media (max-width: 992px) {
    max-width: none;
    margin: 0 0 40px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.errorMessage {
  margin: 8px 0 0;
  text-align: right;
  color: $error;

  @media (max-width: 992px) {
    text-align: center;
  }
}

.button {
  max-width: 292px;
  width: 100%;
  margin: 0 50px 0 0;

  &:last-of-type {
    margin: 0 0 0 auto;
  }

  @media (max-width: 992px) {
    max-width: none;
    padding: 18px 47px;
    margin: 0;

    &:not(:last-of-type) {
      margin: 0 0 16px;
    }
  }
}