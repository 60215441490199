@import "../../../../../common/styles/_variables.scss";

.verified-circle {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $new-gray;

  &--sm {
    & .verified-circle__icon {
      flex-shrink: 0;
      width: 17px;
      height: 17px;

      svg {
        max-width: 9px;
        max-height: 11px;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    background: $action-color;
    border-radius: 50%;
  }

  &__text {
    &--hidden {
      display: none;
    }
  }
}
