@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.payment {
  max-width: 570px;
  width: 100%;
  padding: 24px;

  @include layout-flip {
    padding: 45px 16px;
  }

  h2 {
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $black-text;
  }

  p {
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: $new-gray;
  }

  .get {
    display: block;
  }

  .header {
    margin: 0 0 80px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    color: $black-text;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 26px;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0.25px;
    }

    .premium {
      position: relative;
      color: $action-color;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 170px;
        height: 15px;
        transform: translateY(calc(100% + 5px));
        background: url("../../../../assets/images/premium-decoration-green.svg")
          no-repeat;

        @include layout-flip {
          width: 128px;
          background-size: 128px;
          left: -15px;
        }
      }
    }
  }

  .billingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 36px;

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 33px;
    }

    .price {
      @include layout-flip {
        margin: 0 0 33px;
      }

      h2 {
        margin: 0 0 12px;
      }
    }

    .paymentRadio {
      @include layout-flip {
        width: 100%;
      }
    }
  }

  .totalContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px;

    .totalPrice {
      h2 {
        margin: 0 0 12px;
      }

      p {
        text-align: end;
      }
    }
  }

  .dontHaveCards {
    @include layout-flip {
      padding: 54px 0 0;
      border-top: 1px solid $deep-gray;
    }

    p {
      margin: 0 0 32px;
      font-size: 17px;
      line-height: 20px;
      color: $black-text;

      @include layout-flip {
        margin: 0 0 54px;
        letter-spacing: -0.01em;
      }
    }

    .addNewCard {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover,
      &:focus {
        svg {
          filter: none;

          rect {
            fill: #22c7a0;
          }
        }

        span {
          border-color: transparent;
        }
      }

      svg {
        width: 50px;
        height: 50px;
        margin: 0 8px 0 0;
        filter: drop-shadow(0 10px 15px rgba(69, 199, 184, 0.31));

        rect {
          transition: all 0.3s ease-in-out 0s;
        }
      }

      span {
        display: inline-block;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $accent-color;
        border-bottom: 1px solid;
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  .proceed {
    margin: 62px auto 0;
    max-width: 383px;
    width: 100%;

    @include layout-flip {
      max-width: none;
      margin: 20px 0 0;
    }
  }

  .warning {
    max-width: 569px;
    display: flex;
    margin: 0 0 32px;
    padding: 32px;
    border: 1px solid #3ceac1;
    border-radius: 10px;

    @include layout-flip {
      flex-direction: column;
      padding: 16px;
    }
  }

  .warningIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    margin: 0 32px 0 0;
    padding: 24px;
    background: #defaf6;
    border-radius: 50%;

    @include layout-flip {
      width: 48px;
      height: 48px;
      margin: 0 0 16px;
      padding: 0;
    }

    svg {
      width: 24px;
      height: 24px;

      @include layout-flip {
        width: 16px;
        height: 16px;
      }

      path {
        fill: #000000;
      }
    }
  }

  .warningInfo {
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      font-size: 15px;
    }
  }
}
