@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.creditCardInfo {
  margin: 0 0 30px;

  @include layout-flip {
    margin: 0 0 34px;
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 40px;
    background: white;
    border: 1px solid $deep-gray;
    border-radius: 10px;

    &.error {
      border-color: $error;
    }

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 34px;
      border-color: transparent transparent $deep-gray;
      border-radius: 0;

      &.error {
        border-color: transparent transparent $error;
      }
    }

    .leftSide {
      .header {
        display: flex;
        align-items: center;
        margin: 0 0 24px;

        @include layout-flip {
          margin: 0 0 16px;
        }

        @include layout-flip {
          flex-direction: column;
          align-items: flex-start;
        }

        svg {
          width: 80px;
          height: 56px;
          margin: 0 16px 0 0;

          @include layout-flip {
            order: 2;
            width: 60px;
            height: 42px;
            margin: 0 0 16px;
          }
        }

        h2 {
          margin: 0 16px 0 0;
          font-family: "Mulish", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: $black-text;

          @include layout-flip {
            order: 3;
            font-size: 17px;
            letter-spacing: 0.25px;
          }
        }

        .defaultCardMessage {
          padding: 12px;
          font-family: "Public Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.01em;
          color: $accent-color;
          background: $light-mint;
          border: 1px solid $action-color;
          border-radius: 6px;

          @include layout-flip {
            order: 1;
            margin: 0 0 16px;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .infoField {
        margin: 0 0 32px;

        &:last-of-type {
          margin: 0;
        }

        @include layout-flip {
          display: none;
        }

        h3 {
          margin: 0 0 16px;
          font-family: "Public Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: $black-text;
        }

        p {
          margin: 0;
          font-family: "Public Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: $new-gray;
        }
      }
    }

    .buttons {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 25px 30px;
        font-style: normal;

        @include layout-flip {
          padding: 11px 8px;
        }

        svg {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
        }
      }

      .makeDefaultButton {
        margin: 0 0 8px;
      }

      .removeCardButton {
        background: #fbfcff;
      }
    }
  }

  .errorMessage {
    color: $error;
    margin: 8px 0 0;
    font-size: 13px;
  }
}
