@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/mixins.scss";

.popupContainer {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#1f342f, 0.23);

  @include layout-flip {
    align-items: end;
    background: rgba(#000000, 0.3);
  }
}
