@import "../../common/styles/_variables.scss";

.almostready {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  &:after {
    content: "";
    width: 700px;
    height: 370px;
    position: absolute;
    left: 42%;
    bottom: 0;
    background: url("../../assets/images/frames/frame_bottom.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
  &:before {
    content: "";
    width: 123px;
    height: 128px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    background: url("../../assets/images/frames/frame-right.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}
.almostready-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
.almostready-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: all 0.2s ease-out;
  &:before {
    content: "";
    width: 240px;
    height: 209px;
    position: absolute;
    left: 0;
    top: 127px;
    background: url("../../assets/images/frames/frame.png") center no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
  &:after {
    content: "";
    position: absolute;
    width: 248px;
    height: 263px;
    left: 0;
    bottom: 0;
    background: url("../../assets/images/frames/frame-left.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }
}

.almostready-title {
  &__caption {
    position: relative;
    margin: 0 0 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 126px;
    transition: all 0.2s ease-out;
    text-align: center;
  }
  &__posttitle {
    margin: 0 0 4px;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #68768b;
  }
}

@media all and (max-width: 1400px) {
  .almostready:after {
    left: 30%;
  }
}
@media all and (max-width: 1024px) {
  .almostready {
    &:after,
    &:before {
      display: none;
    }
  }
  .almostready-container:before {
    opacity: 0.5;
  }
  .almostready-container:after {
    display: none;
  }
}
@media all and (max-width: 480px) {
  .almostready-container {
    display: block;
    padding-top: 100px;
  }
  .almostready-container:before {
    top: 24px;
    height: 149px;
    width: 170px;
    background-size: contain;
  }
  .almostready-title__caption {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 35px;
  }
  .almostready-title__posttitle {
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 18px;
  }
}
