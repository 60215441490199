@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 425px;
  border: 1px $deep-gray solid;
  border-radius: 15px;
}

.image {
  height: 306px;
  width: 100%;
  display: block;
  object-fit: cover;

  background: #c4c4c4;
  border-radius: 15px 15px 0 0;
}

.likeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  aspect-ratio: 1 / 1;
  padding: 10px;
  border-radius: 50px;

  @include layout-flip {
    top: 16px;
    right: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 38px 40px 41px;
  position: relative;

  @include md {
    padding: 30px 20px;
  }
}

.message_btn {
  position: absolute;
  top: -26px;

  &.round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    padding: 0;
    border-radius: 50%;

    svg {
      margin: 0;
      transform: scale(1.4);

      path {
        fill: #ffffff;
      }
    }

    & > span {
      display: none;
    }
  }
}

.title {
  display: flex;
  align-items: flex-start;

  min-height: 58px;
}

.name {
  margin: 0;
  font-family: Mulish, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.1;
  color: $black-text;
}

.verified {
  display: flex;
  align-items: center;
  margin-left: 10px;

  span {
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $new-gray;
    margin-left: 6px;
  }
}

.text {
  font-family: "Public Sans", sans-serif;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.16px;
  margin: 16px 0;
  color: $new-gray;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
}

.address {
  min-height: 20px;
}

.professionalSpecialtyNames {
  min-height: 40px;
  padding: 4px 0;
}

.description {
  margin: 0 0 25px !important;
  font-size: 17px !important;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #68768b;
}

.map_marker {
  margin-right: 10px;
}

.options {
  display: flex;
  margin-bottom: 27px;
}

.option {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid $action-color;
  box-sizing: border-box;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  margin-right: 12px;

  &[data-name="in_person_session_availability"] {
    &:hover,
    &:focus {
      &:before {
        content: "In-Person";
      }
    }
  }

  &[data-name="telephone_session_availability"] {
    &:hover,
    &:focus {
      &:before {
        content: "Telephone";
      }
    }
  }

  &[data-name="video_session_availability"] {
    &:hover,
    &:focus {
      &:before {
        content: "Video";
      }
    }
  }

  &[data-name="online_chat_sessions_availability"] {
    &:hover,
    &:focus {
      &:before {
        content: "Chat Connect";
      }
    }
  }

  &:hover,
  &:focus {
    &:before {
      position: absolute;
      padding: 8px;
      top: -43px;
      transform: translateX(calc(-50%));
      left: 50%;
      right: 50%;
      display: table;
      font-size: 16px;
      line-height: 19px;
      color: #1f342f;
      white-space: nowrap;
      background: $action-color;
      border-radius: 5px;

      @media (max-width: 1024px) {
        top: -32px;
        font-size: 13px;
        line-height: 15px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: -11px;
      transform: translateX(calc(-50%)) rotate(45deg);
      left: 50%;
      right: 50%;
      display: table;
      width: 8px;
      height: 8px;
      background: $action-color;
      @media (max-width: 1024px) {
        top: -5px;
      }
    }
  }
}

.seeMoreButton {
  margin: auto 0 0 0;
}

.favorite {
  .options {
    margin: 0;
  }
}

.stateLicenses {
  margin-top: 16px;
}
