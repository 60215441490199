@import "../../../../../../common/styles/variables.scss";
@import "../../../../../../common/styles/mixins";

.backdrop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 805px;
  width: 100%;
  margin: 0 0 32px;
  padding: 40px;
  background: white;
  border: 1px solid $deep-gray;
  border-radius: 10px;

  @include layout-flip {
    flex-direction: column;
    padding: 0 0 32px;
    background: none;
    border: none;
  }

  h2 {
    margin: 0 0 24px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $black_text;

    @include layout-flip {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px;

    &:last-of-type {
      margin: 0;
    }

    p {
      margin: 0;
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: $new_gray;

      @include layout-flip {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
