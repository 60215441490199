@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  position: relative;

  .notification {

    @include clean-btn;
    width: 52px;
    height: 52px;
    border: 1px solid $deep-gray;
    border-radius: 8px;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border: 1px solid #b2c3d9;
    }

    @media (max-width: 1350px) {
      margin: 0 16px 0 0;
    }

    .bell {
      width: 20px;
      height: 20px;

      @include layout-flip {
        height: 24px;
      }
    }

    .accent {
      position: absolute;
      transform: translate(6px, -7px);
      width: 12px;
      height: 12px;
    }

    @include layout-flip {
      width: 42px;
      height: 42px;
      border: none;
      border-radius: 6px;
      transition: all 0.3s ease-in-out 0s;
      background: #FFFFFF;
    }
  }

  .menu {
    z-index: 115;
    position: absolute;
    right: 0;
    top: 60px;
    width: 490px;
    max-height: 459px;
    margin: 0;
    padding: 32px 24px;
    background: #ffffff;
    border: 1px solid #d4e1f2;
    border-radius: 10px;
    list-style: none;
    overflow: auto;

    @include layout-flip {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      padding: 16px 24px 32px 13px;
      border: 0;
      border-radius: 0;
    }
  }

  .menuTitle {
    margin: 0 0 35px;
    padding: 0 0 0 28px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 1.21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.25px;
    color: #1f342f;
  }

  .item {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 28px;

    &:not(:last-child) {
      margin: 0 0 25px;
    }

    &.active {
      &:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0px;
        top: 10px;
        background: #fe4949;
        border: 2px solid #ffffff;
        border-radius: 50%;
      }

      .itemTitle {
        font-weight: 700;
      }
    }
  }

  .itemTitle {
    margin: 0 0 9px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #1f342f;
  }

  .itemText {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.18;
    letter-spacing: -0.01em;
    color: #68768b;

    &:not(:last-child) {
      margin: 0 0 10px;
    }
  }

  .itemCenter {
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.18;
    letter-spacing: -0.01em;
    color: #68768b;
  }

  .chevronIcon {
    flex-shrink: 0;
    width: 18px;
    height: 24px;

    path {
      fill: #1f342f;
    }
  }
}

.layout {
  z-index: 14;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
