@import "../../../../../common/styles/mixins";

.root {
  position: relative;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 213px 50px 244px;

  @include layout-flip {
    flex-direction: column-reverse;
    align-items: center;
    padding: 29px 24px 67px;
  }

  .info {
    flex-shrink: 0;
    max-width: 600px;

    @media (max-width: 1280px) {
      flex-shrink: 1;
      margin: 0 20px 0 0;
    }
  }

  .title {
    margin: 0 0 24px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 5px;
      font-size: 33px;
    }
  }

  .text {
    max-width: 556px;
    margin: 0 0 32px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.36;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 16px;
    }
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .item {
    display: flex;
    align-items: center;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      font-size: 16px;
    }

    & + .item {
      margin: 32px 0 0;
    }

    svg {
      flex-shrink: 0;
      width: 45px;
      height: 45px;
    }

    span {
      margin: 0 0 0 16px;
    }
  }

  .image {
    position: absolute;
    top: 148px;
    right: -10%;
    width: 850px;

    @media (max-width: 1440px) {
      position: static;
      top: auto;
      right: auto;
      width: 60%;
    }

    @media (max-width: 1280px) {
      max-height: 600px;
    }

    @include layout-flip {
      margin: 0 0 34px;
    }

    @media (max-width: 480px) {
      width: 100%;
      max-height: 280px;
    }
  }
}
