@import "../../../common/styles/_variables.scss";

.root {
  &::before {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  :global .sign-container::before {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  :global .sign-wrapper {
    max-width: 574px;
    width: 100%;

    @media (max-width: $mobile) {
      padding: 0 23px;
    }
  }

  :global .sign-right-block__text {
    max-width: 555px;

    @media (max-width: $desktop-l) {
      max-width: 405px;
    }
  }

  .title {
    margin: 0 0 13px;
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.26;
    color: $black-text;

    @media (max-width: $mobile) {
      margin: 0 0 8px;
      font-size: 26px;
    }
  }

  .subtitle {
    position: relative;
    display: block;
    margin: 0 0 60px;
    text-align: center;
    font-size: 16px;
    line-height: 1.18;
    color: $new-gray;

    @media (max-width: $mobile) {
      margin: 0 0 80px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -60px;
        width: 56px;
        height: 38px;
        background: url("../../../assets/images/frames/green-plane.png")
          no-repeat center / contain;
      }
    }
  }

  .descr {
    margin: 0 0 20px;
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    line-height: 1.25;
    color: $black-text;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px;
    padding: 16px 7px 16px 16px;
    list-style: none;
    border: 1px solid $deep-gray;
    border-radius: 15px;

    @media (max-width: $mobile) {
      border: none;
      padding: 0;
    }
  }

  .listInner {
    padding: 0 9px 0 0;
  }

  .button {
    max-width: 386px;
    width: 100%;
    margin: 0 auto 24px;

    @media (max-width: $mobile) {
      margin: 0 auto 16px;
      padding: 18px 25px;
    }
  }

  .link {
    cursor: pointer;
    display: block;
    width: min-content;
    margin: 0 auto 30px;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.12;
    color: $accent-color;
    border: none;
    border-bottom: 1px solid $accent-color;
    transition: all 0.3s ease 0s;
    background: none;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
  }

  .sub {
    display: block;
    margin: 0 0 42px;
    text-align: center;
    font-size: 16px;
    line-height: 1.18;
    color: $new-gray;

    @media (min-width: $mobile) {
      display: none;
    }
  }
}

.circle {
  position: relative;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 24px;
  background: rgba($action-color, 0.1);
  border-radius: 50%;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    height: 68px;
    background: rgba($action-color, 1);
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    background: rgba($action-color, 0.3);
    border-radius: 50%;
  }

  .circleIcon {
    position: relative;
    width: 25px;
    height: 18px;
  }
}
