@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.favorites {
  .header {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    display: flex;
    align-items: center;
    color: $black-text;
  }

  .cards {
    display: grid;
    gap: 28px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 40px 0 0;

    @include xl {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include lr {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include md {
      grid-template-columns: minmax(0, 1fr);
    }

    @include layout-flip {
      gap: 16px;
      margin: 24px 0 0;
    }
  }

  .viewMore {
    width: 428px;
    margin: 70px auto 0;

    @include layout-flip {
      width: 100%;
    }
  }
}

