@import "../../styles/variables";
@import "../../styles/mixins";

.progressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;

  svg {
    transform: rotate(-90deg);

    circle {
      fill: none;
      stroke: #F1F7FF;
      stroke-width: 13px;
    }

    .progressAccent {
      stroke: $action-color;
      stroke-linecap: round;
    }
  }

  h1 {
    position: absolute;
    margin: 0;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $black-text;
  }
}