@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/_mixins.scss";

.sign {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  @media (max-width: $mobile) {
    &.title-sending {
      .sign-container {
        padding: 20px 0 0;
      }

      .title {
        font-weight: 400;
        font-size: 40px;
        line-height: 1.12;
      }
    }
  }

  .sign-logo {
    display: block;
    max-width: 660px;
    width: 100%;
    max-height: 41px;
    margin: 0 auto 50px;
    padding: 0 130px;



    @media (max-width: 1500px) {
      padding: 0 75px;
    }

    @media (max-width: 768px) {
      text-align: center;
      padding: 0;
    }
  }
}

.sign-container {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100vh;
  padding: 60px 0 65px;
  transition: all 0.2s ease-out;
  background: url("../../../assets/images/frames/frame-left.png") left bottom
      no-repeat local,
    url("../../../assets/images/frames/frame.png") left top 126px no-repeat
      local,
    url("../../../assets/images/frames/frame_bottom.png") bottom right -400px no-repeat
      local;
}

.sign-wrapper {
  max-width: 684px;
  margin: auto;

  @media (min-width: 1500px) {
    position: relative;
    padding: 0 0 0 130px;
  }
}

.sign-title {
  display: flex;
  align-items: center;
  margin: 0 0 26px;

  &__caption {
    position: relative;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 58px;
    transition: all 0.2s ease-out;

    &--uncircle:after {
      display: none;
    }
  }

  &__suptitle {
    margin: 0 0 24px 7px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  &__posttitle {
    margin: 0 0 32px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #68768b;
  }
}

.sign-form {
  max-width: 500px;

  .field {
    margin-bottom: 24px;
  }
}

.sign-forgot {
  margin-bottom: 24px;
  text-align: right;
}

.sign-checkbox {
  margin-bottom: 16px;

  .checkbox__input + label {
    display: block;
    font-size: 14px;
    line-height: 16px;
  }
}

.sign-submit {
  margin-bottom: 16px;
}

.sign-google {
  margin-bottom: 16px;
}

.sign-help {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: #68768b;
}

.sign-already {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: #68768b;
  text-align: center;
}

.sign-right-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: rgba(212, 225, 242, 0.3);
  background-image: url("../../../assets/images/frames/frame-right.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 40%;
  height: 100vh;
  transition: all 0.2s ease-out;

  .photo-chat {
    align-self: flex-end;
    width: 100%;
    padding: 0 0 0 60px;

    @media all and (max-width: 1500px) {
      padding: 0 0 0 30px;
    }
  }

  &__text {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    line-height: 70px;
    color: #1f342f;
    margin: 75px 0 0;
    transition: all 0.2s ease-out;
    white-space: pre-wrap;
  }
}

@media all and (max-width: 1500px) {
  .sign:after {
    left: 30%;
  }

  .sign-title__caption {
    font-size: 36px;

    &:after {
      width: 190px;
      right: -6px;
      top: 0;
      background-size: contain;
    }
  }

  .sign-right-block {
    &__text {
      font-size: 36px;
      line-height: 50px;

      &:after {
        top: -27px;
        right: -24px;
        width: 220px;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .sign-container {
    width: 100%;
    background-image: url("../../../assets/images/frames/frame.png");
    background-position: left top 24px;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: 181px 149px;
  }

  .sign-right-block {
    display: none;
  }
}

@media all and (max-width: 480px) {
  .sign-container {
    display: block;
    padding: 30px 0 0;
  }

  .sign-container:before {
    top: 24px;
  }

  .sign-title {
    margin-bottom: 16px;
  }

  .sign-title__caption {
    font-size: 28px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .sign-title__suptitle {
    display: none;
  }

  .sign-title__posttitle {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 19px;
  }

  .sign-wrapper {
    padding: 0 24px;
    margin: 0 auto;
  }
}
@media all and (max-width: 374px) {
  .sign-title__caption {
    font-size: 24px;
  }

  .sign-forgot {
    margin-bottom: 28px;
  }

  .sign-forgot__link.g-underline {
    font-size: 15px;
    line-height: 18px;
  }

  .password {
    margin-bottom: 8px;
  }

  .sign-submit {
    margin-bottom: 16px;
  }

  .sign-google {
    padding: 23px 38px;
  }
}
