@import "../../../styles/variables";
@import "../../../styles/mixins";

.root {
  width: 100%;
  margin-top: 16px;
  position: relative;
  min-height: 30px;
}

.text {
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $new-gray;
  margin: 0 0 8px;
}

.progress_text {
  display: flex;
  align-items: center;
  color: #a7b2cd;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #a7b2cd;
  margin: 0 8px;
}

.bar_container {
  height: 3px;
  background: $deep-gray;
  border-radius: 10px;
  width: 100%;
  margin-top: 16px;
}

.bar {
  height: 3px;
  background: $action-color;
  border-radius: 10px;
}

.remove {
  @include clean-btn;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
}
