@import "../../../../../../common/styles/mixins";

.treatmentPreferences {
  & + .treatmentPreferences {
    margin: 30px 0 0;
  }
}

.treatmentPreferencesTitle {
  margin: 0 0 48px;
  padding: 0;
  font-family: "Mulish", sans-serif;
  font-size: 34px;
  line-height: 1.3;
  color: $black-text;

  @include layout-flip {
    display: none;
  }
}

.treatmentPreferencesInner {
  padding: 40px;
  background: #ffffff;
  border: 1px solid $deep-gray;
  border-radius: 10px;

  @media (min-width: 1366px) {
    display: flex;
  }

  @include layout-flip {
    padding: 3px 0 0;
    border: none;

    &.specialtyInner {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.treatmentPreferencesInfo {
  max-width: 950px;
  flex: 1 0 auto;

  @include layout-flip {
    flex-direction: column;
  }
}

.treatmentPreferencesBlock {
  flex: 0 0 50%;
  margin: 0 0 40px;
  padding: 0 20px 0 0;

  &.specialtyBlock,
  &:last-of-type {
    margin: 0;
  }

  @include layout-flip {
    margin: 0 0 40px;
    padding: 0;

    .treatmentPreferencesBlockText {
      max-width: 100%;
    }
  }
}

.treatmentPreferencesBlockTitle {
  margin: 0 0 16px;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: $black-text;

  @include layout-flip {
    margin: 0 0 10px;
  }
}

.treatmentPreferencesBlockText {
  max-width: 346px;
  margin: 0;
  padding: 0;
  font-family: "Public Sans";
  font-size: 17px;
  line-height: 1.2;
  color: $new-gray;

  @include layout-flip {
    max-width: 100%;
  }
}

.treatmentPreferencesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 72px;
  max-width: 292px;
  width: 100%;
  margin: auto 9% auto auto;

  svg {
    margin: -2px 10px 0 0;
  }

  @media (max-width: 1366px) {
    margin: 20px 0 0;
  }

  @include layout-flip {
    display: none;

    &.specialtyButton {
      display: flex;
      max-width: 120px;
      width: 100%;
      height: 44px;
      margin: 0 0 15px auto;
      padding: 0;
    }
  }
}

.treatmentPreferencesList {
  margin: 0 -16px;
}

.treatmentPreferencesItem {
  padding: 17px 31px 17px 16px;
  border: 1px solid $deep-gray;
  border-radius: 6px;

  div {
    border-radius: 10px;
  }
}
