@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.modal {
  align-items: center;
}

.popup {
  position: relative;
  max-width: 780px;
  width: 100%;
  padding: 70px 70px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include layout-flip {
    display: block;
    max-width: 327px;
    width: 100%;
    height: auto;
    padding: 40px 15px;
    border-radius: 15px 15px 15px 15px;

    &::before {
      content: "";
      position: absolute;
      margin: 16px 0 0 0;
      left: 44%;
      top: 0;
      width: 12%;
      height: 2px;
      background-color: #a7b3ce;
      border-radius: 20px;
    }
  }
}

.popupContainer {
  max-width: 565px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: $mobile) {
    max-width: 327px;
    align-items: center;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    margin: 10px;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  color: $black-text;

  @media (max-width: $mobile) {
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 20px;
  }
}

.caption {
  font-size: 23px;
  line-height: 29px;
  margin: 0 0 50px;

  @include layout-flip {
    padding: 0 20px;
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 25px;
  }
}

.buttonBlock {
  display: flex;
  justify-content: space-between;
  padding: 0 45px;

  @include layout-flip {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}

.popupButton {
  max-width: 200px;
  width: 100%;
  margin: 0 0 0;


  @media (max-width: $mobile) {
    max-width: 100%;
    margin: 0 0 10px;

    &:last-of-type {
      background-color: #DEFAF6;
      border-color: #DEFAF6;
    }
  }
}
