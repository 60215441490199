@import "../../../../../common/styles/mixins";

.inner {
  padding: 60px 0;
  background: #dce8fa;

  @include layout-flip {
    padding: 25px 24px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 968px;
  margin: 0 auto;
  padding: 0 50px;

  @include layout-flip {
    display: block;
    text-align: center;
    padding: 0;
  }
}

.title {
  margin: 0 0 23px;
  padding: 0;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.1;
  color: #000000;

  @include layout-flip {
    margin: 0 0 10px;
    font-size: 25px;
  }
}

.subtitle {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  color: #68768b;

  @include layout-flip {
    margin: 0 0 15px;
    font-size: 14px;
    line-height: 1.4;
  }
}

.button {
  max-width: 250px;
  width: 100%;

  @include layout-flip {
    max-width: none;
    padding: 18px 47px;
  }
}

.wrapper {
  max-width: 1565px;
  margin: 0 auto;
  padding: 120px 50px 0;

  @include layout-flip {
    padding: 46px 24px 0;
  }
}

.featuredTitle {
  margin: 0 0 12px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 1.2;
  text-align: center;
  color: #000000;

  @include layout-flip {
    margin: 0 0 16px;
    font-size: 33px;
  }
}

.featuredSubtitle {
  margin: 0 0 64px;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.05;
  text-align: center;
  color: #68768b;

  @include layout-flip {
    max-width: 280px;
    margin: 0 auto 24px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.card {
  width: 100%;
  background: #ffffff;
  height: 100%;
  margin: 0 auto;

  :global .btn {
    pointer-events: none;
  }
}

.viewButton {
  max-width: 375px;
  width: 100%;
  margin: 45px auto 0;

  @include layout-flip {
    max-width: 500px;
    margin: 20px auto 46px;
    padding: 18px 47px;
  }

  @media (max-width: 568px) {
    max-width: none;
  }
}

.slider {
  width: 100%;
  margin: 0 auto;

  a {
    width: 100%;

    @media (max-width: 475px) {
      width: 290px;
    }
  }

  @include layout-flip {
    min-width: 600px;
    margin-left: 20px;
  }

  @media (max-width: 600px) {
    :global .swiper-pagination {
      left: 27%;
      right: auto;
    }
  }
}

.iconsSlider {
  @media (max-width: 600px) {
    :global .swiper-wrapper {
      margin: 0 0 0 -30px;
    }
  }
}
