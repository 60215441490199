@import "common/styles/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;900&family=Public+Sans:wght@400;500;600;700&family=Montserrat+Alternates:wght@400;500;600;700&display=swap");
@import "react-toastify/dist/ReactToastify.min.css";
@import "common/styles/mixins";

.no-scroll {
  overflow: hidden !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  //scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Public Sans", sans-serif;
  color: $black-text;
  min-width: 320px;
  background-color: #fff;
}

#root {
  main {
    height: 100%;
  }
}

.title {
  font-family: "Mulish", sans-serif;
}

#bugherd_embed_communication_frame {
  width: 83px !important;
  height: 70px !important;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.g-underline {
  cursor: pointer;
  color: #26d4b6;
  font-weight: 500;
  border-bottom: 1px solid;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus {
    border-color: transparent;
  }
}

textarea::-webkit-input-placeholder {
  font-family: "Public Sans", sans-serif;
}

textarea:-ms-input-placeholder {
  font-family: "Public Sans", sans-serif;
}

textarea:-moz-placeholder {
  font-family: "Public Sans", sans-serif;
}

textarea::-moz-placeholder {
  font-family: "Public Sans", sans-serif;
}

.toast {
  width: fit-content;
  max-width: 375px;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  color: #1f342f;

  @include layout-flip {
    width: 100%;
    max-width: 327px;
    top: 88px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    line-height: 1.2;
  }

  &__item {
    padding: 0;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    background: #f2f6fb;
    border-radius: 10px;
    box-shadow: none;

    .Toastify__toast-icon {
      display: none;
    }

    .toast__body {
      padding: 20px 24px;
    }

    &.Toastify__toast--warning {
      background: #ffe3aa;
    }

    &.Toastify__toast--success {
      background: #3ceac1;
    }

    &.Toastify__toast--error {
      color: #ffffff;
      background: #fe4949;
    }

    &.Toastify__toast--info {
      background: #3ceac1;
      border-radius: 10px;
    }
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
