@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.backdrop {
  height: fit-content;
  margin: 16px;
  padding: 24px;
  background: white;
  border: 1px solid $deep-gray;
  border-radius: 10px;

  @include layout-flip {
    width: 100%;
    margin: 0 0 16px;
  }

  .top {
    display: flex;

    .circle {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      margin: 0 16px 0 0;
      background: #f4f5f8;
      border: 1px solid $deep-gray;
      border-radius: 1000px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .description {
      flex-grow: 1;

      h3 {
        font-family: "Public Sans", sans-serif;
        margin: 0 0 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: $new-gray;
      }

      p {
        font-family: "Public Sans", sans-serif;
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $black-text;

        span:last-child {
          font-size: 16px;

          @include layout-flip {
            font-size: 13px;
          }
        }

        @include layout-flip {
          font-family: "Mulish", sans-serif;
          font-size: 17px;
          line-height: 28px;
        }
      }
    }
  }

  .progress {
    margin: 32px 0 0;

    @include layout-flip {
      margin: 24px 0 0;
    }
  }
}