@import "../../../../../common/styles/mixins";

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1334px;
  margin: 0 auto;
  padding: 0 50px 152px 50px;

  @media (min-width: 1440px) {
    min-height: 742px;
  }

  @include layout-flip {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 0 24px 29px;
  }

  .info {
    max-width: 510px;
    flex-shrink: 0;

    @media (max-width: 1440px) {
      margin: 0 20px 0 0;
    }

    @include layout-flip {
      margin: 0;
    }
  }

  .title {
    margin: 0 0 24px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 5px;
      font-size: 33px;
    }
  }

  .text {
    max-width: 465px;
    margin: 0 0 32px;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      max-width: 330px;
      margin: 0 0 25px;
      font-size: 16px;
    }
  }

  .button {
    max-width: 230px;
    width: 100%;

    @include layout-flip {
      max-width: none;
      padding: 18px 47px;
    }
  }

  .image {
    @include layout-flip {
      width: 60%;
      margin: 0 0 25px;
    }

    @media (max-width: 520px) {
      width: 100%;
    }
  }
}
