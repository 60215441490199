@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  background: #defaf6;
  border-color: #defaf6;
  transition: all 0.3s ease-in-out 0s;

  &.premium {
    background: $action-color;
    border-color: $action-color;

    &:hover,
    &:focus {
      background: #22c7a0;
      border-color: #22c7a0;
    }
  }

  &.available {
    background: $action-color;
    border-color: $action-color;

    &:hover,
    &:focus {
      background: #22c7a0;
      border-color: #22c7a0;
    }
  }

  &.wait {
    background: #ffda79;
    border-color: #ffda79;

    &:hover,
    &:focus {
      background: #ffda79;
      border-color: #ffda79;
    }
  }

  &.offline {
    background: $red;
    border-color: $red;

    &:hover,
    &:focus {
      background: $red;
      border-color: $red;
    }
  }
}

.icon {
  margin-right: 5px;
}
