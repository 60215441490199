@import "../../styles/variables";
@import "../../styles/mixins";

.header {
  background: #f1f7ff;

  .header__user-notification {
    display: flex;
  }

  .mobileNotification {
    margin: 0 0 0 auto;

    & > button {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1234px;
    margin: 0 auto;
    padding: 30px 0;

    @include xl {
      padding: 0;
    }
  }

  &__nav {
    flex-grow: 2;
  }

  &__ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    display: inline-block;
    color: #1f342f;
    transition: all 0.2s;
    padding: 0 40px 0 0;
    font-size: 20px;
    line-height: 24px;
  }

  &__li {
    display: inline-block;
    list-style: none;

    &:last-of-type {
      .header__link {
        padding: 0;
      }
    }
  }

  &__btn {
    padding: 14px 48px;
    margin: 0 0 0 40px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#header__toggle {
  display: none;
}

@media (max-width: $desktop-xl) {
  .header {
    padding: 30px 25px;
  }
}

@media all and (max-width: 1024px) {
  .header {
    z-index: 10;
    position: fixed;
    top: -1px;
    right: 0;
    left: 0;
    justify-content: normal;
    padding: 16px !important;

    .logo {
      svg {
        width: 135px;
      }
    }

    &__btn.btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 40px;
      border-radius: 10px;
      background-color: #3ceac1;
      border: 1px solid #d4e1f2;

      & span {
        font-family: "Public Sans";
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1;
        color: #1f342f;
        margin: 12px;
      }

      svg {
        margin: 0 0 0 10px;
      }

      &.logout {
        height: 24px;
        width: 24px;
        background: url("../../../assets/images/icons/log-out.svg") center
          no-repeat;

        svg {
          display: none;
        }
      }
    }

    &__burger {
      width: 42px;
      height: 42px;
      margin-right: 10px;
      cursor: pointer;
      background-image: url("../../../assets/images/menu.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
      box-shadow: 0 10px 30px rgba(197, 209, 226, 0.3);
      border-radius: 6px;
      z-index: 101;
    }

    &__nav {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: -150%;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 13px 24px;
      list-style: none;
      background-color: #ffffff;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
      transition: all 0.2s ease-out;
      z-index: 1000;
      overflow-y: auto;
    }

    &__ul {
      flex-direction: column;
      text-align: center;
      padding: 33px 0 0;
    }

    &__li {
      display: block;
      width: 100%;
      border-bottom: 1px solid #ebf2fb;

      .header__link {
        margin: 0;
        padding: 38px 10px;
        font-family: "Public Sans", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.1;
      }

      &:last-of-type {
        border-bottom: 1px solid transparent;

        .header__link {
          padding: 38px 10px;
        }
      }
    }

    &__link {
      display: block;
      width: 100%;
      padding: 16px;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      text-decoration: none;
      transition: all 0.2s ease-out;
      cursor: pointer;

      &:last-of-type {
        .header__link {
          padding: 16px;
        }
      }
    }

    &__upgrade-to-premium {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0 0;
      padding: 34px 40px 30px;
      background: url("../../../assets/images/frames/frame-left-purple.png")
          left top/100px no-repeat,
        url("../../../assets/images/frames/frame-left-green.png") left bottom -72px/170px
          no-repeat,
        #f1f7ff;
      border: 1px solid $deep-white;
      border-radius: 20px;

      h5 {
        margin: 0 0 8px;
        text-align: center;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $accent-color;
      }

      p {
        margin: 0 0 8px;
        text-align: center;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: $new-gray;
      }

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  .header__nav.active {
    left: 0 !important;
  }
}
