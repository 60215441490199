@import "../../../../../../common/styles/mixins";
@import "../../../../../../common/styles/variables";

.root {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin: 0 0 24px;
  }

  .position {
    min-width: 14px;
    margin: 0 16px 0 0;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: #1f342f;

    @include layout-flip {
      margin: 20px 16px auto 0;
    }
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .input {
    max-width: 387px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: 1360px) {
      max-width: 250px;
    }

    @include layout-flip {
      max-width: none;
    }
  }

  .label {
    width: 100%;
    flex: 0 1 auto;
    margin: 0 0 0 16px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: #1f342f;

    @include layout-flip {
      max-width: 293px;
      margin: 16px 0 0;
    }
  }
}
