@import "../../../styles/mixins";

.browseButton,
.removeBtn,
.removalOverlayButton {
  cursor: pointer;
  outline: none !important;

  &:disabled {
    cursor: default;
  }
}

.browseButton {
  @include restrict-prop(height, 48px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background: #f1f7ff;
  border: 1px solid #d4e1f2;
  border-radius: 10px;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
  transition: all 0.3s ease-in-out 0s;

  &:not(:disabled):hover {
    background: #b2c3d9;
    border: 1px solid #dedede;
  }
}

.preview {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  --height: 134px;
  --width: 134px;
  @include restrict-prop(width, var(--width));
  @include restrict-prop(height, var(--height));

  @include layout-flip {
    --height: 80px;
    --width: 80px;
  }

  &_large {
    --height: auto;
    --width: 100%;

    @include layout-flip {
      --height: 400px;
    }
  }

  @include layout-flip {
    cursor: pointer;
  }
}

.verifiedIcon {
  pointer-events: all;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 25px;
  width: 25px;
  opacity: 0.5;
  transition: all 0.3s ease 0s;

  &:hover {
    opacity: 1;
  }

  &.verifiedIconDisplay {
    pointer-events: none;
    opacity: 1;
  }
}

.verticalImage {
  max-height: unset;
  max-width: 100%;
}

.horizontalImage {
  max-width: unset;
  max-height: 100%;
}

.removeBtn {
  background: none;
  border-top: none;
  border-right: none;
  padding: 0;
  border-left: none;
  font-size: 13px;
  line-height: 15px;
}

.multiPreviewContainer {
  margin-top: 32px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  column-gap: 32px;
  row-gap: 20px;
  width: 100%;

  .removeBtn {
    margin-top: 8px;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.removalOverlayBackdrop {
  background: rgba(31, 52, 47, 30%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 8px;
  animation: slide-up 200ms linear forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 200;

  &_disappearing {
    animation: slide-down 200ms linear forwards;
  }
}

.removalOverlayButton {
  width: 100%;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #1f342f;
  background: white;
  border-radius: 14px;
  padding: 18px 16px;
  border: none;

  &:first-child {
    margin-bottom: 16px;
  }
}

.documentPreview {
  height: 100%;
}

.progressBar {
  display: inline;
}

.photoIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}
