@import "../../styles/variables";

.checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox__input + label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  color: #68768b;

  &.--error {
    &::before {
      border-color: $error;
    }
  }
}

.label-size {
  &--sm {
    line-height: 16px;
    font-size: 14px;
  }

  &--md {
    line-height: 18px;
    font-size: 16px;
  }
}

.checkbox__input + label a {
  font-size: inherit;
  line-height: inherit;
}

.checkbox__input + label::before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1.5px solid #d4e1f2;
  border-radius: 4px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  cursor: pointer;
  vertical-align: middle;
}

.checkbox__input:focus:not(:checked) + label:not(.--error)::before {
  border-color: #d4e1f2;
}

.checkbox__input:checked + label::before {
  border-color: #3ceac1;
  background-color: #3ceac1;
  background-image: url("../../../assets/images/check.svg");
  background-size: 15px;
}

.checkbox__input:disabled + label::before,
.checkbox__input:disabled + label {
  opacity: 0.8;
  cursor: default;
}

.checkbox-size-md {
  font-size: 16px;
}
