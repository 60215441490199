.button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    .iconWrapper {
      background: #22c7a0;
    }

    .text {
      border-bottom: 1px solid transparent;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  background: #3ceac1;
  box-shadow: 0 10px 15px rgba(69, 199, 184, 0.31);
  border-radius: 50px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out 0s;
}

.text {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid #26d5b6;
  color: #26d5b6;
  transition: all 0.2s ease-in-out 0s;
}
