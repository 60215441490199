@import "../../../../common/styles/mixins";

.root {
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1334px;
  width: 100%;
  margin: 0 auto;
  padding: 45px 50px 40px;

  @include layout-flip {
    align-items: normal;
    padding: 32px 24px 20px;
  }

  .login {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    font-size: 13px;
    line-height: 15px;
    color: #68768b;

    & .loginIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 40px;
      border-radius: 10px;
      background-color: #3ceac1;
      border: 1px solid #d4e1f2;

      & span {
        font-family: "Public Sans";
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1;
        color: #1f342f;
        margin: 12px;
      }
    }

    svg {
      margin-left: 10px;
    }
  }

  .tooltipContainer {
    position: relative;

    button {
      padding: 20px 59px;
    }

    &:not(:last-of-type) {
      margin: 0 30px 0 0;
    }

    .tooltip {
      position: absolute;
      bottom: -15px;
      right: 0;
      transform: translateY(100%);
      padding: 25px;
      background: #ffffff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s;

      &.open {
        opacity: 100%;
        pointer-events: auto;
      }

      a {
        display: block;
        text-align: right;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $black-text;
        white-space: nowrap;
        transition: all 0.3s ease-in-out 0s;

        &:hover,
        &:focus {
          color: #22c7a0;
        }

        &:not(:last-of-type) {
          margin: 0 0 25px;
        }
      }
    }
  }

  .headerButtons {
    display: flex;
    z-index: 2;

    @include layout-flip {
      display: none;
    }
  }

  .logo {
    width: 212px;
    height: 41px;
    margin: 0 20px 0 0;

    svg {
      height: 100%;
      width: 100%;
    }

    @include layout-flip {
      width: 188px;
      height: 36px;
    }
  }

  .btn {
    min-width: 200px;
    padding: 20px 38px;
  }

  .btnRow {
    margin: 0 30px 0 0;

    @include layout-flip {
      margin: 25px 0;
    }
  }
}
