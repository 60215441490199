@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.testStart {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @include layout-flip {
    margin-bottom: 30px;
  }
}

.title {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  font-size: 34px;
  line-height: 43px;
  font-weight: normal;
  color: #1D3B34;

  @include layout-flip {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}

.btn {
  max-width: 292px;
  width: 100%;

  @include layout-flip {
    max-width: none;
  }
}