@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.wrapper {
  display: flex;
  min-height: 100vh;
  height: 100vh;

  @include layout-flip {
    margin: -74px 0 0;
    flex-direction: column;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 70%;
  min-height: 100%;
  background-image: url("../../../assets/images/frames/frame.png"),
    url("../../../assets/images/frames/frame-left.png");
  background-position: 0 100px, 0 100%;
  background-repeat: no-repeat, no-repeat;
  padding: 100px 50px 20px;
  overflow-y: auto;

  @include layout-flip {
    position: relative;
    order: 1;
    padding: 24px 24px 181px;
    background-image: none;
    min-height: calc(100vh - 126px);
  }
}

.progress {
  position: relative;
  flex: 0 0 auto;
  text-align: center;
  background-color: #f2f6fb;
  background-image: url("../../../assets/images/frames/frame-right.png");
  background-position: calc(100% - 20px) 10px;
  background-repeat: no-repeat;
  padding: 0 25px;

  @include layout-flip {
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    padding: 24px 24px 29px;
    height: auto;
    width: 100%;
    background-image: none;
  }
}

.content {
  max-width: 812px;
  width: 100%;
  margin: 0 0 auto;
}

.actions {
  margin-top: 24px;
  text-align: center;

  @include layout-flip {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 24px 34px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #d4e1f2;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px;
  column-gap: 40px;

  @include layout-flip {
    margin-bottom: 17px;
    column-gap: 16px;
  }
}

.button {
  width: 100%;
  height: 66px;

  @include layout-flip {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 155px;
    height: 60px;
    padding: 0;

    &.full-width {
      max-width: 100%;
    }
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 46px;
  line-height: 58px;
  color: #1f342f;
  margin: 0;

  @include layout-flip {
    display: none;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.row {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.rowLabel {
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
  margin-bottom: 16px;
}

.rowSubLabel {
  min-height: 30px;
  font-family: "Public Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: #68768b;
  margin-top: -10px;
  margin-bottom: 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;

  @include layout-flip {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 16px;
  }
}

.rowShort {
  display: grid;
  grid-template-columns: auto 84px;
  grid-column-gap: 8px;

  @include layout-flip {
    padding-bottom: 14px;
  }
}

.rowShortLabel {
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
  margin-bottom: 16px;
}

.hint {
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #26d5b6;

  @include layout-flip {
    margin: 0 0 32px;
    text-transform: none;
  }

  &.mobile_hidden {
    @include layout-flip {
      display: none;
    }
  }
}

.line {
  display: flex;
  flex-direction: row;
  @media all and (max-width: 1024px) {
    flex-direction: column;
  }
}
