.List {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}
