@import "../../../common/styles/_variables.scss";

.available {
  display: flex;
  align-items: center;
  max-width: 227px;
  height: 40px;
  padding: 5px 8px 5px 12px;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: -0.01em;
  color: $accent-color;
  background: $light-mint;
  border: 1px dashed $accent-color;
  box-sizing: border-box;
  border-radius: 5px;

  svg {
    width: 15px;
    height: 19px;
    margin: -2px 10px 0 0;
  }

  &--red {
    max-width: 245px;
    padding: 5px 8px 5px 7px;
    color: $red;
    background: $light-red;
    border: 1px dashed $pink;

    svg {
      width: 24px;
      height: 24px;
      margin: -1px 8px 0 0;
    }
  }

  span {
    white-space: nowrap;
  }
}
