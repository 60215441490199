//colors
$color-main: #069b5f;
$action-color: #3ceac1;
$accent-color: #26d5b6;
$light-mint: #defaf6;
$green-lighter: #4fe5d4;

$black-text: #1f342f;
$new-gray: #68768b;
$deep-gray: #d4e1f2;
$light-gray: #f4f8fc;
$gray: #c4c4c4;
$deep-white: #d4e1f2;
$purple: #854BFF;

$light-red: #f9e0e6;
$pink: #ffdde2;
$red: #fe4949;
$error: red;
$warning: #b58b00;

//containers
$container-max: 1693px;
$background-max-width: 3000px;

// resolutions
$min-width: 320px;
$mobile-sm-var: 374px;
$mobile-var: 480px;
$mobile: 576px;
$responsive-var: 768px;
$layout-flip-var: 900px;
$desktop-s: 1024px;
$desktop: 1200px;
$grid-width: 1240px;
$desktop-m: 1366px;
$desktop-l: 1440px;
$desktop-xl: 1600px;
$desktop-fhd: 1920px;
$desktop-qhd: 2560px;
