.filter-tag {
  display: flex;
  align-items: center;
  background: #F4F8FC;
  border: 1px solid #D4E1F2;
  border-radius: 50px;
  padding: 16px;
  max-height: 48px;
  color: #68768B;
  column-gap: 8px;

  &--interactive {
    cursor: pointer;

    &:hover {
      border: 1px solid #A7B2CD;
      background: #fff;
    }
  }

  &__text {
    display: inline-block;
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;
  }

  &__button {
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover svg path {
      fill: #68768B
    }
  }
}
