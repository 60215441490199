@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.field {
  margin: 0 0 16px;

  @include layout-flip {
    margin: 0 0 13px;
  }

  .label {
    display: block;
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 1.1;
    color: $black-text;

    @include layout-flip {
      margin: 0 0 10px;
    }
  }

  .inputContainer {
    & > input.inputField {
      width: 100%;
      height: 59px;
      border: 1px solid $deep-gray;

      &:hover,
      &:focus {
        border: 1px solid #4adccc;

        & + .inputDropdown {
          border-left: 1px solid #4adccc;
          border-top: 1px solid #4adccc;
          border-bottom: 1px solid #4adccc;
        }
      }
    }

    &.inputContainerError {
      .inputField {
        border: 1px solid $error;
      }

      .inputDropdown {
        border-top: 1px solid $error;
        border-bottom: 1px solid $error;
        border-left: 1px solid $error;
      }
    }

    :global .country-list {
      border: 1px solid $deep-white;
      border-radius: 5px;
    }
  }

  .errorMessage {
    color: $error;
    margin: 4px 0 0;
    font-size: 13px;
  }
}
