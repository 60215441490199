@import "../../../../../../../common/styles/variables";
@import "../../../../../../../common/styles/mixins";

.profileInfoCard {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include md {
    flex-direction: column;
    flex-grow: 1;
  }

  h2 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: $black-text;

    @include md {
      order: 2;
    }
  }

  small {
    display: block;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $new-gray;

    @include layout-flip {
      text-align: center;
    }
  }

  .avatar {
    flex-shrink: 0;
    position: relative;
    margin: 0 55px 0 0;

    @include lr {
      margin: 0 20px 0 0;
      text-align: center;
    }

    @include md {
      margin: 0 0 21px;
    }

    &.withRemove {
      .editIcon {
        bottom: -8px;

        @include md {
          bottom: 12px;
        }
      }
    }

    img {
      width: 152px;
      height: 152px;
      border-radius: 15px;
      object-fit: cover;

      @include md {
        width: 80px;
        height: 80px;
      }
    }

    .editIcon {
      position: absolute;
      right: -23px;
      bottom: -12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      padding: 0;
      background-color: white;
      border: none;
      border-radius: 50px;
      box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);

      @include md {
        right: -16.6px;
        bottom: -11px;
        width: 36px;
        height: 36px;
      }

      svg {
        margin: 0;
      }
    }

    .hiddenInput {
      display: none;
    }
  }

  .removeAvatar {
    @include clean-btn;

    justify-content: flex-start;
    width: auto;
    text-align: left;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #26d5b6;
    border-bottom: 1px solid #26d5b6;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border-color: transparent;
    }

    @include md {
      margin: 8px 0 0;
    }
  }

  .info {
    margin: 0 55px 0 0;

    @include lr {
      margin: 0 12px 0 0;
    }

    @include md {
      margin: 0 0 16px;
    }

    h2 {
      margin: 0 0 8px;

      @include md {
        display: block;
        text-align: center;
        font-size: 23px;
        line-height: 34px;
        letter-spacing: 0.25px;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    @include lg {
      width: 100%;
    }
    @include md {
      flex-direction: column;
    }
  }

  .recommended {
    position: relative;
    display: flex;
    align-items: center;
    height: 164px;
    padding: 24px;
    background: #defaf6;
    border: 1px solid #3ceac1;
    border-radius: 10px;

    svg {
      flex-shrink: 0;
      @include lg {
        width: 34px;
        height: 34px;
        margin-bottom: 12px;
      }
    }

    @include lg {
      padding: 28px 12px;
      width: 100%;
      align-items: normal;
      flex-direction: column;
    }
  }

  .text {
    margin: 0 16px 0 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1d3b34;
    @include lg {
      margin: 0 0 20px;
    }
  }

  .therapists {
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    @include lg {
      position: absolute;
      top: 28px;
      right: 12px;
    }

    img {
      position: relative;
      width: 46px;
      height: 46px;
      border: 1px solid #fff;
      border-radius: 15px;
      z-index: revert;

      &:not(:first-child) {
        margin-left: -20px;
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          z-index: #{10 - $i};
        }
      }
    }
  }

  .link {
    margin-left: 24px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out 0s;

    @include lg {
      align-self: end;
    }

    &:after {
      content: "";
      margin-left: 9px;
      display: inline-block;
      width: 10px;
      height: 6px;
      background: url("../../../../../../../assets/images/icons/arrow.svg")
        center no-repeat;
      transform: rotate(-90deg);
      background-size: cover;
    }

    span {
      color: #26d4b6;
      font-weight: 500;
      border-bottom: 1px solid;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
  }
}
