@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins";

.subscription {
  @include layout-flip {
    padding: 26px 0 0;
  }

  .title {
    margin: 19px 0;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    color: $black-text;
  }

  .tabs {
    display: flex;
    align-items: center;

    .tabList {
      width: 50%;
      margin: 0 77px 0 0;

      @include layout-flip {
        width: 100%;
        margin: 0;
      }
    }

    .addNewCard {
      display: flex;
      align-items: center;
      margin: 0 0 0 40px;
      cursor: pointer;

      &:hover,
      &:focus {
        svg {
          filter: none;

          rect {
            fill: #22c7a0;
          }
        }

        span {
          border-color: transparent;
        }
      }

      svg {
        width: 50px;
        height: 50px;
        margin: 0 8px 0 0;
        filter: drop-shadow(0 10px 15px rgba(69, 199, 184, 0.31));

        rect {
          transition: all 0.3s ease-in-out 0s;
        }
      }

      span {
        display: inline-block;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $accent-color;
        border-bottom: 1px solid;
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }
}
