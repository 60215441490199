@import "../../common/styles/mixins";

.wrapper {
  max-width: 684px;
  margin: 0 auto;

  @media all and (max-width: 480px) {
      padding: 0 24px;
      margin: 0 auto;
  }
}

.logo {
  padding: 0 !important;
}

.signInput {
  margin-bottom: 16px;

  @include layout-flip {
    margin-bottom: 13px;
  }
}

.upload {
  margin: 16px 0;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0 0 16px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #68768b;
  }

  a {
    color: $action-color;
  }
}