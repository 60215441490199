@import "./variables";

@mixin xxs {
  @media (max-width: #{$mobile-sm-var}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$responsive-var}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$desktop-s}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$grid-width}) {
    @content;
  }
}

@mixin lr {
  @media (max-width: #{$desktop-l}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$desktop-xl}) {
    @content;
  }
}

@mixin restrict-prop($prop, $val) {
  #{$prop}: $val;
  min-#{$prop}: $val;
  max-#{$prop}: $val;
}

@mixin layout-flip {
  @media (max-width: #{$layout-flip-var}) {
    @content;
  }
}

@mixin min-layout-flip {
  @media (min-width: #{$layout-flip-var}) {
    @content;
  }
}

@mixin max-bg {
  @media (min-width: #{$background-max-width}) {
    @content;
  }
}

@mixin clean-btn {
  outline: none !important;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  justify-content: center;

  &:disabled {
    cursor: default;
  }
}

@mixin round-btn($size) {
  @include clean-btn;
  @include restrict-prop(width, $size);
  @include restrict-prop(height, $size);
  border-radius: 50%;
}
