@import "../../common/styles/mixins";

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &:after {
    content: "";
    width: 700px;
    height: 370px;
    position: absolute;
    left: 42%;
    bottom: 0;
    background: url("../../assets/images/frames/frame_bottom.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;

    @include layout-flip {
      display: none;
    }
  }

  &:before {
    content: "";
    width: 123px;
    height: 128px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    background: url("../../assets/images/frames/frame-right.png") center
      no-repeat;
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  .container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    transition: all 0.2s ease-out;

    &:before {
      content: "";
      width: 240px;
      height: 209px;
      position: absolute;
      left: 0;
      top: 127px;
      background: url("../../assets/images/frames/frame.png") center no-repeat;
      z-index: -1;
      transition: all 0.2s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      width: 248px;
      height: 263px;
      left: 0;
      bottom: 0;
      background: url("../../assets/images/frames/frame-left.png") center
        no-repeat;
      z-index: -1;
      transition: all 0.2s ease-out;

      @include layout-flip {
        display: none;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    @include layout-flip {
      width: 100%;
      margin: auto;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin: 0 0 30px;
    background: #defaf6;
    border: 8px solid #26d4b6;
    border-radius: 50%;
  }

  .title {
    margin: 0 0 32px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.2;
    text-align: center;
    color: #1f342f;

    @include layout-flip {
      font-size: 28px;
    }
  }

  .text {
    margin: 0 0 32px;
    font-family: "Public Sans", sans-serif;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    color: #68768b;

    @include layout-flip {
      font-size: 16px;
    }
  }

  .button {
    max-width: 383px;
    width: 100%;
    background: #defaf6;
    border-color: #defaf6;

    &:hover {
      border-color: #d7faf5;
      background: #d7faf5;
      box-shadow: 0 0 10px 2px #defaf6;
    }

    @include layout-flip {
      max-width: 600px;
      padding: 18px 47px;
    }
  }
}
