@import "../../styles/mixins";
@import "../../styles/variables";

.tooltipContainer {
  position: relative;

  .tooltip {
    left: 50%;
    bottom: 100%;
    transform: translate(-110px, -20px);
    position: absolute;
    padding: 24px;
    background: white;
    border: 1px solid $deep-gray;
    border-radius: 4px;
    box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
    opacity: 1;
    transition: 0.2s;
  }

  .triangle {
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -2px);
    position: absolute;
    border: 10px solid white;
    border-color: white transparent transparent transparent;
    opacity: 1;
    transition: 0.2s;
  }

  .disabled {
    pointer-events: none;
    opacity: 0;
  }

  .trigger {
    cursor: pointer;
  }
}
