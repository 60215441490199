@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";


.startRecordingBtn {
  padding: 0 16px;
  display: flex;
  align-items: center;
  @include restrict-prop(height, 48px);
}

.moveOnBtn {
  @include round-btn(60px);
  background: $action-color;

  &:disabled {
    background: $accent-color;
  }
}

.container {
  position: relative;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 0 40px;

  @include layout-flip {
    margin: 42px 0 37px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    margin: 0 0 24px;
    background: $light-mint;
    border-radius: 30px;

    @include layout-flip {
      width: 106px;
      height: 106px;
      margin: 0 0 15px;
    }

    svg {
      width: 64px;
      height: 88px;

      @include layout-flip {
        width: 48px;
        height: 66px;
      }
    }
  }

  h2 {
    margin: 0 0 8px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: $black-text;

    @include layout-flip {
      font-size: 20px;
      line-height: 25px;
    }
  }

  p {
    max-width: 530px;
    margin: 0;
    text-align: center;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: $new-gray;

    @include layout-flip {
      font-size: 14px;
    }
  }
}

.form {
  margin: 0;
}

.cancelButton {
  --size: 70px;
  --image-size: 26px;
  @include round-btn(var(--size));
  background: #ffffff;
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 5;

  img {
    @include restrict-prop(height, var(--image-size));
    @include restrict-prop(width, var(--image-size));
  }

  @include layout-flip {
    --size: 48px;
    --image-size: 22px;
    top: 46px;
    left: 16px;
  }
}
