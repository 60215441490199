@import "../../../../common/styles/_mixins.scss";

.card {
  display: flex;
  flex-direction: column;
  margin: 15px 30px 15px 0;
  height: 100%;

  @include layout-flip {
    width: 100%;
    justify-content: flex-start;
    margin: 12px 0;
    flex-direction: row;
    align-items: center;
  }

  .columnImage {
    width: 380px;
    height: 215px;

    @include layout-flip {
      width: 100%;
      max-width: 115px;
      height: 115px;
      margin: 0 15px 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }

  .textBlock {
    max-width: 380px;
    display: flex;
    flex-direction: column;
  }

  .columnTitle {
    height: 92px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 46px;
    color: #000000;
    overflow: hidden;

    @include layout-flip {
      min-height: 0;
      height: auto;
      margin: 0 0 10px 0;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .date {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #68768b;
    margin: 0;

    @include layout-flip {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .categoryLinks {
    display: flex;

    @include layout-flip {
    }

    a {
      max-width: unset;
      width: unset;
      margin: 10px 5px 0 0;
      align-items: unset;

      @include layout-flip {
        margin: 0 5px 0 0;
      }
    }
  }

  .categoryLink {
    p {
      display: block;
      margin: 16px 0 0 0;
      font-family: "Public Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.1px;
      color: #68768b;
      text-decoration: underline;
      text-underline-offset: 2px;

      @include layout-flip {
        font-size: 10px;
        margin: 10px 0 0;
      }
    }
  }

  .captions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

  .author {
    display: flex;
    align-items: center;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1px;
    color: #68768b;

    @include layout-flip {
      margin: 5px 0 0 0;
    }

    p {
      margin: 0;

      @include layout-flip {
        font-size: 10px;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;

      @include layout-flip {
        width: 12px;
        height: 12px;
        margin: 0 5px 0 0;
      }

      path {
        fill: #68768b;
      }
    }
  }
}
