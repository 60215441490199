@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins";

.settings {
  .title {
    margin: 19px 0;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    color: $black-text;
  }

  .tabs {
    ul:first-of-type {
      @include layout-flip {
        justify-content: space-between;
      }
    }

    > div {
      padding: 0;
    }
  }

  .content {
    padding: 32px 0;
  }
}
