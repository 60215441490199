@import "../../styles/mixins";

$input-zone-height: 59px;

.control {
  padding: 18px 16px 18px 8px !important;
  height: $input-zone-height;
}

.dropdownIndicator {
  padding: 0 !important;
}

.menuHolder {
  top: $input-zone-height !important;
  overflow: hidden;
}

.customInput {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    line-height: 19px;
    height: 19px;

    &::placeholder {
      color: #68768b;
    }
  }
}

.valueDisplay {
  margin-top: 24px;
  column-gap: 16px;
  row-gap: 8px;
  display: flex;
  flex-wrap: wrap;
}

.multiSelectLabel {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
  margin-bottom: 10px;
  display: block;
}

.multiSelectSubLabel {
  font-family: "Public Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: #68768b;
  margin: 0 0 16px;
}

.menuList {
  box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
  border-radius: 4px;
  background: white;
  padding: 16px 24px;
}

.menuListActions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  padding-top: 16px;

  button {
    width: 143px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.optionContainer {
  padding: 10px 8px;
  border-radius: 5px;
  cursor: pointer;

  &_focused {
    background: #defaf6;
  }
}

.menuListContent {
  max-height: 262px;
  overflow: auto;
}

.withColumns {
  .menuListContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
