@import "../../styles/variables";

$strong-color: #26d5b6;

.label {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
}

.password {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 19px 16px;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus,
  &.active {
    border: 1px solid #4adccc;
  }

  &.invalid {
    border-color: red;
  }

  &__input {
    padding: 0;
    width: calc(100% - 30px);
    font-size: 20px;
    line-height: 19px;
    border: none;
    background: transparent;
    outline: none;
    color: #1f342f;
    transition: all 0.2s ease-out;

    &::placeholder {
      font-size: 16px;
    }
  }

  &__visible {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__tip {
    margin: 5px 0 0;
    font-size: 13px;
    line-height: 15px;
    color: #68768b;
    margin-bottom: 8px;
    text-align: right;

    span {
      display: block;
    }
  }

  &__strength {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__status {
    display: flex;
    flex-grow: 1;

    &.--weak .true {
      background: $error;
    }

    &.--fine .true {
      background: $warning;
    }

    &.--strong .true {
      background: $strong-color;
    }
  }

  &__status-line {
    width: 25%;
    margin-right: 8px;
    height: 3px;
    background: #d4e1f2;
    border-radius: 5px;
  }

  &__strength_text {
    font-size: 13px;
    line-height: 15px;
    margin-left: 5px;
    white-space: nowrap;
    flex-basis: 105px;
    text-align: right;

    &.--weak {
      color: $error;
    }

    &.--fine {
      color: $warning;
    }

    &.--strong {
      color: $strong-color;
    }
  }
}

.error_msg {
  margin: 4px 0 0;
  font-size: 13px;
}

@media all and (max-width: 480px) {
  .label {
    margin-bottom: 10px;
  }
}
