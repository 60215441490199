@import "src/common/styles/variables";
@import "src/common/styles/mixins";

.therapist-card {
  display: flex;
  padding: 0 0 48px;
  border-bottom: 1px solid #d4e1f2;
  margin: 0 0 48px;
  position: relative;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &__availability {
      margin: 0 0 0 5px;
      padding: 4px 6px 4px 9px;
      font-size: 12px;
      line-height: 1;
    }
  }

  &__left {
    width: 177px;
    flex-shrink: 0;
    margin-right: 48px;

    @media (max-width: 1024px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 61px;
      margin-right: 16px;
    }

    @media (max-width: 1024px) {
      &-avatar {
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }

    &-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 9px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      text-align: left;
      letter-spacing: 0;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__msg-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    flex-shrink: 0;
    margin-right: 10px;
    background: url("../../../../../assets/images/icons/comment-dots.svg") center no-repeat;
    vertical-align: middle;
  }

  &__main {
    width: 100%;
    margin: 0 48px 0 0;

    @media (max-width: 1024px) {
      margin: 0;
    }
  }

  &__caption {
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      min-height: 75px;
      margin: 0 50px 4px 90px;
    }

    @media (max-width: 420px) {
      align-items: flex-start;
      min-height: 85px;
      margin: 0 50px 0 90px;
    }

    & .available {
      height: auto;

      @media (max-width: 1024px) {
        margin: 10px 0;
      }
    }
  }

  &__name {
    max-width: 100%;
    margin-right: 16px;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: #1f342f;

    @media (max-width: 1024px) {
      flex-shrink: 0;
      margin: 0 4px 0 0;
      font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }

    @media (max-width: 420px) {
      max-width: 148px;
      padding: 0 5px 0 0;
      line-height: 1.1;
    }
  }

  &__verified {
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
    font-size: 16px;
    line-height: 19px;
    color: #68768b;

    @media (max-width: 1024px) {
      font-size: 0;
      margin-right: 4px;
    }

    &-icon {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      margin-right: 8px;
      background: url("../../../../../assets/images/icons/verified.svg") center no-repeat;

      @media (max-width: 1024px) {
        margin-right: 0;
        width: 17px;
        height: 17px;
        background-size: cover;
      }
    }
  }

  &__unavailable {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.05em;
    color: #fe4949;
    background: #ffdde2;
    border: 1px dashed #ffdde2;
    box-sizing: border-box;
    border-radius: 5px;

    @media (max-width: 1024px) {
      font-size: 0;
      padding: 4px;
    }

    &-icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 9px;
      background: url("../../../../../assets/images/icons/unavailable-user.svg") center no-repeat;

      @media (max-width: 1024px) {
        margin-right: 0;
        width: 17px;
        height: 17px;
        background-size: cover;
      }
    }
  }

  &__like {
    margin: 0 16px 0 0;
    flex-shrink: 0;

    @media (max-width: 1024px) {
      position: absolute;
      margin: 0;
      top: 0;
      right: 0;
    }

    @media (max-width: 420px) {
      width: 35px;
      height: 35px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__company {
    display: block;
    margin: 8px 0 0;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;

    @media (max-width: 1024px) {
      min-height: 16px;
      margin: 0 0 25px;
    }
  }

  &__info {
    margin: 0 0 16px;

    @media (max-width: 1024px) {
      min-height: 16px;
      margin: 0 0 25px 90px;
    }

    &-tags {
      margin: 0 0 16px;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $new-gray;

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 16px;
        border: none;
        margin: 0;
        padding: 0;
      }
    }

    &-address {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $new-gray;

      @media (max-width: 1024px) {
        display: none;
      }

      &-text {
        white-space: nowrap;

        @media (max-width: 1280px) {
          min-width: 200px;
          white-space: normal;
        }
      }

      &-icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  &__options {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #68768b;

    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 16px;

      span {
        max-width: 159px;
      }

      &-margin {
        margin-top: 24px;
      }
    }

    &-item {
      position: relative;
      margin: 0 0 0 16px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      background-color: #fff;
      border: 1px solid $action-color;
      box-sizing: border-box;
      border-radius: 50px;
      cursor: pointer;

      @media (max-width: 1024px) {
        width: 35px;
        height: 35px;
        margin: 0 0 0 8px;
      }

      &.person-icon {
        background-image: url("../../../../../assets/images/icons/offline.svg");
      }

      &.phone-icon {
        background-image: url("../../../../../assets/images/icons/phone-gray.svg");
      }

      &.video-icon {
        background-image: url("../../../../../assets/images/icons/video-gray.svg");
      }

      &.chat-icon {
        background-image: url("../../../../../assets/images/icons/text.svg");
      }

      &:hover {
        &:before {
          content: attr(aria-label);
          position: absolute;
          padding: 8px;
          top: -53px;
          transform: translateX(calc(-50%));
          left: 50%;
          right: 50%;
          display: table;
          font-size: 16px;
          line-height: 19px;
          color: #1f342f;
          white-space: nowrap;
          background: #3ceac1;
          border-radius: 5px;

          @media (max-width: 1024px) {
            top: -32px;
            font-size: 13px;
            line-height: 15px;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: -22px;
          transform: translateX(calc(-50%)) rotate(45deg);
          left: 50%;
          right: 50%;
          display: table;
          width: 8px;
          height: 8px;
          background: #3ceac1;
          @media (max-width: 1024px) {
            top: -5px;
          }
        }
      }
    }
  }

  &__description {
    max-width: 660px;
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #68768b;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__right {
    width: 304px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 100%;
    }

    &-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      background: #f4f8fc;
      border-color: #f4f8fc;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
      }

      &.btn-premium {
        background: $action-color;
        border-color: $action-color;

        &:hover,
        &:focus {
          background: #22c7a0;
          border: 1px solid #22c7a0;
        }
      }

      &.btn-available {
        background: $action-color;
        border-color: $action-color;

        &:hover,
        &:focus {
          background: #22c7a0;
          border: 1px solid #22c7a0;
        }
      }

      &.btn-wait {
        background: #ffda79;
        border-color: #ffda79;

        &:hover,
        &:focus {
          background: #ffda79;
          border: 1px solid #ffda79;
        }
      }

      &.btn-offline {
        background: $red;
        border-color: $red;

        &:hover,
        &:focus {
          background: $red;
          border: 1px solid $red;
        }
      }

      @media (max-width: 1024px) {
        justify-content: center;
        padding: 15px 47px;
        margin: 0 0 16px;
        border-radius: 5px;
      }
    }

    &-call {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.1;
      color: #1f342f;

      > span {
        display: flex;
        flex-direction: column;
      }

      small {
        flex: 0 0 100%;
        margin: 5px 0 0;
        font-size: 18px;
        color: #1f342fa6;
        letter-spacing: -1px;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 46px;
        height: 46px;
        margin-right: 12px;
        border: 1px solid $action-color;
        border-radius: 50%;
        background: url("../../../../../assets/images/icons/phone.svg") center no-repeat;
        background-size: 19px;
      }

      &.disabled {
        pointer-events: none;

        &:before {
          filter: grayscale(1);
        }
      }
    }

    &-profile {
      @include layout-flip {
        padding: 19px 47px;
      }
    }
  }
}

.licenseIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  object-fit: contain;
}

.stateLicenses {
  display: flex;
  align-items: center;

  margin: 10px 0 10px;
  font-size: 18px;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: #68768b;
}
