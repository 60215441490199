@import "../../../styles/variables";
@import "../../../styles/mixins";

.desktopHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 130px;
  flex-shrink: 0;
  padding: 0 90px;
  border-bottom: 1px solid #ccdaee;

  @media (max-width: 1350px) {
    padding: 0 40px;
  }

  .circle {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 2px 8px 0 10px;
    border-radius: 11px;
  }

  .circle.green {
    background: $action-color;
  }

  .circle.yellow {
    background: #ffda79;
  }

  .circle.red {
    background: #fe4949;
  }

  .chatStatus {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 32px 0 0;
    padding: 16px 12px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $new-gray;
    border: solid 1px #D4E1F2;
    background: #FFFFFF;

    p {
      margin: 0;
      text-transform: capitalize;
    }

    @media (max-width: 1350px) {
      margin: 0 16px 0 0;
    }

    @media (max-width: 1200px) {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
