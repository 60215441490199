@import "../../../styles/variables";
@import "../../../styles/mixins";

.desktopAside {
  width: 285px;
  padding: 45px 35px;
  background-color: #f2f6fb;

  .logo {
    width: 212px;
    height: 41px;
    margin-bottom: 100px;
  }

  .tabs {
    width: 220px;

    .link {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      padding: 18px;
      text-align: start;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }
  }
}
