@import "../../../../../../../common/styles/variables";
@import "../../../../../../../common/styles/mixins";

.testInfoMobile {
  display: flex;
  align-items: flex-start;
  padding: 36px 0;
  border-bottom: 1px solid $deep-gray;

  &:first-of-type {
    padding: 0 0 36px 0;
  }

  &:last-of-type {
    border: none;
  }

  .toggleOpenButton {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    padding: 10px;
    border-radius: 100px;

    svg {
      width: 14px;
      filter: brightness(0);
      transform: rotate(180deg) scale(1.3);

      &.flipped {
        transform: rotate(0) scale(1.3);
      }
    }
  }

  .fields {
    margin: 0 0 0 16px;

    .field {
      display: flex;
      margin: 0 -5px;
      padding: 10px 0;

      &.closed {
        padding: 10px 0;

        .name {
          flex: auto;
        }

        .value {
          flex: auto;
          color: $accent-color;
        }
      }

      .name {
        flex: 0 0 35%;
        margin: 0;
        padding: 0 5px;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: $black-text;
      }

      .value {
        display: flex;
        align-items: center;
        flex: 0 0 65%;
        margin: 0;
        padding: 0 5px;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: $new-gray;

        .clockIcon {
          margin: 0 7px 0 0;
        }

        .free {
          padding: 5.5px 16.5px;
          font-family: "Public Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          color: #ffffff;
          background: #7b61ff;
          border-radius: 100px;
        }
      }

      .testScore {
        margin: 0 0 0 20px;
      }

      .takeTestButton {
        padding: 15.5px 18px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
      }
    }
  }
}
