@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/mixins";

.wrapper {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.root {
  max-width: 1234px;
  margin: 0 auto;
  padding: 60px 0 0;

  @media (max-width: $desktop-xl) {
    padding: 60px 24px 0;
  }

  @media (max-width: $desktop-s) {
    padding: 12px 24px 0;
  }

  .content {
    display: flex;
    max-width: calc($container-max + 396px);
    margin: 0 auto;
    padding-top: 32px;

    @media (max-width: $desktop-xl) {
      padding-top: 32px;
    }

    @media (max-width: $desktop-s) {
      padding-top: 12px;
    }
  }

  .container {
    max-width: calc(760px + 30px);
    width: 100%;
    margin: 0 auto;
    padding: 0 56px;

    @media (max-width: 900px) {
      padding: 0;
    }
  }

  .back {
    max-width: 80px;

    @include layout-flip {
      margin: 16px 0 4px;
    }
  }
}
