@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.root {
  position: relative;
  z-index: 2;
  margin: auto 0 0;
  padding: 40px 0;

  background-image: url("../../../../assets/images/backgrounds/footer-background.png");
  background-position: bottom center;
  background-repeat: no-repeat;

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    padding: 36px 0 34px;
    background-image: url("../../../../assets/images/backgrounds/mobile-footer-background.png");
  }

  @media (min-width: 1536px) {
    background-image: url("../../../../assets/images/backgrounds/footer-background-4k.png");
    padding: 64px 0 32px;
    background-position: bottom;
    background-size: auto;
  }

  &.dashboard {
    padding: 40px;
    background: none;
    border-top: 1px solid #ccdaee;
  }

  .socials {
    display: flex;
    align-items: center;

    @include layout-flip {
      justify-content: center;
    }

    a {
      transition: all 0.3s ease 0s;

      &:hover {
        opacity: 0.8;
      }
    }

    a + a {
      margin: 0 0 0 24px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    max-width: 721px;
    width: 100%;
    margin: 0 auto 40px;

    @include layout-flip {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto 25px;
      padding: 0 24px;
    }
  }

  .column {
    margin: 0;
    padding: 0;
    list-style: none;

    @include layout-flip {
      & + .column {
        margin: 25px 0 0;
      }
    }
  }

  .title {
    margin: 0 0 20px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: $black-text;

    @include layout-flip {
      margin: 0 0 15px;
      text-align: center;
    }
  }

  .text {
    max-width: 160px;
    margin: 0 0 60px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    color: $new-gray;

    @include layout-flip {
      max-width: none;
      margin: 0 0 20px;
      text-align: center;
      font-size: 16px;
    }
  }

  .link {
    margin: 0 0 15px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $new-gray;

    @include layout-flip {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }

    &:last-of-type {
      margin: 0;
    }

    a {
      color: inherit;

      @media (min-width: 992px) {
        border-bottom: 1px solid transparent;
        transition: all 0.2s ease 0s;

        &:hover {
          border-bottom: 1px solid $new-gray;
        }
      }
    }
  }

  .input {
    margin: 0 0 21px;

    @include layout-flip {
      margin: 0 0 15px;
    }
  }

  .button {
    width: 100%;

    @include layout-flip {
      padding: 18px 47px;
    }
  }

  .form {
    flex: 0 0 250px;

    @include layout-flip {
      flex: 0 0 100%;
      width: 100%;

      .title {
        margin: 0 0 25px;
      }
    }
  }

  .copy {
    max-width: 721px;
    width: 100%;
    margin: 0 auto;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: $new-gray;

    @include layout-flip {
      text-align: center;
      padding: 0 24px;
    }
  }
}
