.step {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.tagWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  margin-right: 25px;
}

.tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 69px;
  background: #fff;
  box-shadow: 0 25px 30px rgba(186, 205, 232, 0.2);
  border-radius: 14px;
  font-family: "Mulish", sans-serif;
  font-size: 30px;
  color: #26D5B6;
  transition: all 250ms ease-in-out;

  &:after {
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 1px;
    height: 14px;
    background: #68768B;
  }

  .step:last-child &:after {
    content: none;
  }
}

.tagActive {
  width: 86px;
  height: 97px;
  background: #3CEAC1;
  font-size: 46px;
  color: #FFFFFF;
  border-radius: 25px;
}

.title {
  font-family: 'Public Sans', sans-serif;
  font-size: 28px;
  line-height: 33px;
  color: #FFF;
  margin-bottom: 8px;
  transition: all 250ms ease-in-out;

  @media (max-width: 1400px) {
    font-size: 16px;
  }
}

.titleActive {
  font-size: 38px;
  color: #1F342F;

  @media (max-width: 1400px) {
    font-size: 26px;
  }
}

.subTitle {
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #68768B;
  transition: all 250ms ease-in-out;

  @media (max-width: 1400px) {
    font-size: 14px;
  }
}

.subTitleActive {
  color: #26D5B6;
}

.scale {
  width: 120px;
  margin-top: 8px;
  filter: drop-shadow(0px 3px 10px #AEC8EC);
}
