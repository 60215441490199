@import "../../styles/variables";

.btn {
  position: relative;
  display: block;
  padding: 23px 47px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  color: #1f342f;

  background: #3ceac1;
  border: 1px solid #3ceac1;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: #22c7a0;
    border: 1px solid #22c7a0;
  }

  &.btn-color-greenlight {
    background: #defaf6;
    border: 1px solid #defaf6;

    &:hover,
    &:focus {
      background: #b5e8e0;
      border: 1px solid #b5e8e0;
    }
  }

  &.btn-color-white {
    background: #fff;
    border: 1px solid #d4e1f2;

    &:hover,
    &:focus {
      border: 1px solid #b2c3d9;
    }
  }

  &.btn-color-blue {
    background: #defaf6;
    border: 1px solid #defaf6;
  }

  &.btn-color-red {
    background: $red;
    border: 1px solid $red;
    color: #ffffff;
  }

  &.btn-color-yellow {
    color: #d89d03;
    background: #ffda79;
    border: 1px solid #ffda79;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      color: #d89d03;
      background: #ffe3aa;
      border: 1px solid #ffe3aa;
    }
  }

  &.btn-color-turquoise {
    background: #b5e8e0;
    border: none;
    border-radius: 5px;
  }

  &.btn-color-none {
    background: none;
    border: 1px solid #0000;

    &:hover,
    &:focus {
      background: #d4e1f2;
    }
  }

  &:disabled {
    cursor: default;
    color: #6e7890;
    background: #eef3fb;
    border-color: #eef3fb;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background-color: rgba($color: white, $alpha: 0.3);
  }
}

.btn-size-full {
  width: 100%;
}

.btn-size-small {
  width: auto;
  max-width: 150px;
}

.btn-size-wide {
  width: 100%;
  max-width: 380px;
}

.btn-size-auto {
  width: auto;
}

.google-icon {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: inline-block;
  background: url("../../../assets/images/google-icon.svg") center no-repeat;
}

.btn__spinner {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn__spinner::after {
  $size: 25px;
  content: "";
  position: absolute;
  width: $size;
  height: $size;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
