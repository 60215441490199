@import "../../styles/variables";
@import "../../styles/mixins";

.editHeader {
  display: flex;
  align-items: center;
  margin: 0 0 48px;

  button {
    margin: 0 16px 0 0;
  }

  .goBackButton {
    @include clean-btn;
    padding: 15px;
    border-radius: 6px;
    background: $deep-gray;
    transition: all 0.3s ease-in-out 0s;

    @include layout-flip {
      background: none;
    }

    &:hover,
    &:focus {
      background: #b2c3d9;
    }

    .angle {
      width: 10px;
      height: 10px;
      transform: translateX(3px) rotate(45deg);
      border: 2px solid $black-text;
      border-color: transparent transparent $black-text $black-text;

      @include layout-flip {
        border-color: transparent transparent $new-gray $new-gray;
      }
    }
  }

  h1 {
    margin: 0;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    color: $black-text;
  }

  @include layout-flip {
    display: none;
  }

  .addButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: 0;
  }
}
