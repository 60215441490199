@import "../../../../../common/styles/mixins";

.root {
  max-width: 1253px;
  margin: 0 auto;
  padding: 32px 50px 184px;

  @include layout-flip {
    padding: 31px 24px 60px;
  }

  .title {
    max-width: 721px;
    margin: 0 auto 43px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 auto 25px;
      font-size: 33px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @include layout-flip {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include layout-flip {
      flex-direction: row;
      max-width: 300px;
      width: 100%;
      text-align: left;

      & + .column {
        margin: 35px 0 0;
      }
    }
  }

  .columnIcon {
    margin: 0 0 16px;

    @include layout-flip {
      min-width: 83px;
      margin: 0 36px 0 0;
      text-align: center;
    }
  }

  .info {
    @include layout-flip {
      flex-shrink: 0;
    }
  }

  .columnTitle {
    margin: 0 0 16px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 5px;
      font-size: 25px;
    }
  }

  .columnText {
    margin: 0;
    padding: 0;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      font-size: 12px;
    }
  }
}
