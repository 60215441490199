@import "../../../common/styles/variables.scss";
@import "../../../common/styles/mixins.scss";

.root {
  background: #fbfcff;
  padding: 0 0 46px;

  @media (max-width: 992px) {
    padding: 32px 0 26px;
  }

  header {
    margin: 0 0 48px;
    padding: 0 52px 0 36px;
  }

  .logo :global .logo {
    margin: 0 auto 0 0;
  }

  .wrapper {
    max-width: 1554px;
    padding: 0 36px;

    @media (max-width: 1200px) {
      padding: 0 23px;
    }
  }

  .title {
    margin: 0;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 1.18;
    color: #1d3b34;

    @include layout-flip {
      font-weight: 400;
      font-size: 23px;
      letter-spacing: 0.25px;
    }
  }

  .date {
    display: block;
    margin: 0 0 20px 27px;
    line-height: 1;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #1d3b34;

    @include layout-flip {
      font-size: 14px;
    }
  }

  .text {
    margin: 0 0 20px;
    padding: 0;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.18;
    color: #68768b;

    @include layout-flip {
      font-size: 20px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }

  .goBackButton {
    @include clean-btn;
    display: inline-block;
    margin: 0 16px 0 0;

    .angle {
      width: 11px;
      height: 11px;
      transform: translateX(3px) rotate(45deg);
      border: 2px solid $black-text;
      border-color: transparent transparent $black-text $black-text;

      @include layout-flip {
        border-color: transparent transparent $new-gray $new-gray;
      }
    }
  }

  .btn {
    @include layout-flip {
      max-width: 100%;
      width: 100%;
      margin: 70px 0 0;
    }
  }
}
