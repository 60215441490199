@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.root {
  @include layout-flip {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popup {
  position: relative;
  max-width: 781px;
  width: 100%;
  padding: 62px 60px 38px;
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include layout-flip {
    display: block;
    max-width: 327px;
    width: 100%;
    padding: 40px 15px;
    border-radius: 15px;
  }
}

.popupContainer {
  max-width: 387px;
  margin: 0 auto;

  @media (max-width: $mobile) {
    max-width: 327px;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 0;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $black-text;

  @media (max-width: $mobile) {
    max-width: 215px;
    margin: 0 auto 20px;
    font-size: 28px;
  }
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $black-text;

  @include layout-flip {
    margin: 0 0 10px;
  }
}

.popupInput {
  width: 100%;
  margin: 0 0 32px;

  @include layout-flip {
    margin: 0 0 20px;
  }
}

.popupButton {
  max-width: 100%;
  width: 100%;
  margin: 0 0 0;

  @media (max-width: $mobile) {
    margin: 0;
  }
}

.text {
  max-width: 580px;
  margin: 0 auto 15px;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #68768b;

  @media (min-width: 900px) {
    span {
      display: block;
    }
  }

  @include layout-flip {
    margin: 0 auto 10px;
    font-size: 16px;

    span {
      padding: 0 4px 0 0;
    }

    & + .text {
      margin: 0 auto 20px;
    }
  }
}

.link {
  @include clean-btn;
  display: inline;
  color: #26d4b6;
  font-size: inherit;
}

.fieldSet {
  max-width: 386px;
  margin: 30px auto;

  @include layout-flip {
    margin: 0;
  }
}
